.user-voucher {
    padding: 20px;
    width: 100%;
    color: #000000;
}

.user-voucher-row {
    // margin: 0 !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.user-voucher-coin {
    background-color: #f25a41;
    color: #ffffff;
    padding: 20px 0;
}

.voucher-current-detail {
    background-color: #02abec;
    color: #ffffff;
    padding: 20px 0;
}

.voucher-btn-apply {
    background-color: #e8e8e8 !important;
    color: #000000 !important;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px !important;
}

.voucher-detail {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
}

.user-voucher-header {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.voucher-search {
    background-color: #e8e8e8;
    padding: 8px;
    padding-left: 15px;
    padding-right: 30px;
    border-radius: 3px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    width: 100%;
}

.voucher-search-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
}

.search-area {
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 576px) {
    .voucher-btn-area {
        margin-top: 10px;
        padding: 0px !important;
    }
}