.CreateOrderPage {
  background-color: white;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .COP_Title {
    color: black;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 19px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .COP_RowInfo {
    display: flex;
    flex-direction: row;
    // max-width: 600px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .COP_Row_Title {
      color: black;
      width: 200px;
      // text-align: right;
      margin-right: 10px;

      font-size: 13px;
      line-height: 17px;
      font-weight: 600;
    }

    .COP_Row_Content {
      flex: 1;
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      color: black;

      .COP_ROW_Action {
        margin-left: 10px;
        background-color: #e2e2e2;
        color: black;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 3px;

        .COP_Row_Action_Icon {
          margin-right: 5px;
        }
      }

      .COP_ROW_Action:hover {
        // background-color: #e8e8e8;
        opacity: 0.8;
      }

      // list product
      .COP_ListProducts {
        background-color: #f2f2f2;
        flex: 1;
        margin-right: 10px;
        padding-bottom: 0px;

        .UCP_CartItem {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e2e2e2;
          // background-color: white;

          .UCP_CartItem_Image {
            height: 80px;
            width: 80px;
            margin-right: 10px;
            border: 1px solid #f2f2f2;
            object-fit: cover;
          }

          .UCP_CartItem_NamePanel {
            flex: 1;

            .UCP_CartItem_Name {
              margin-bottom: 5px;
              color: black;
              font-size: 14px;
              line-height: 18px;
              font-weight: 550;
            }

            .UCP_CartItem_Price {
              margin-bottom: 10px;
            }

            .UCP_CartItem_ActionPanel {
              display: flex;
              flex-direction: row;

              .UCP_CartItem_Action {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }

          .UCP_CartItem_Total {
            margin-right: 20px;
            color: black;
            font-size: 14px;
            line-height: 18px;
            font-weight: 550;
            text-align: right;
            margin-top: 8px;
          }

          .UCP_CartItem_Quantity {
            display: flex;
            flex-direction: row;
            height: 30px;

            .UCP_CartItem_ButtonDown {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px;
              background-color: #f2f2f2;
              color: gray;
              font-size: 12px;
              cursor: pointer;
              border: 1px solid #d2d2d2;
              border-right: none;
            }

            .UCP_CartItem_QuantityTextBox {
              // margin-right: 5px;
              border: 1px solid #d2d2d2;
              background-color: white;
              color: gray;
              padding: 10px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-weight: bold;
            }

            .UCP_CartItem_ButtonUp {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px;
              background-color: #f2f2f2;
              color: gray;
              font-size: 12px;
              border: 1px solid #d2d2d2;
              cursor: pointer;
              border-left: none;
            }
          }
        }
      }

      .COP_Row_DiscountCode {
        border: none;
        background-color: #f2f2f2;
        padding: 5px;
        color: black;
        width: 200px;
        font-weight: 550;
      }

      .UCP_DiscountItem {
        display: flex;
        background-color: #074f51;
        flex-direction: row;
        // padding: 15px;
        // height: 70px;
        border-radius: 6px;
        color: white;
        position: relative;

        .UCP_DiscountItem_PercentCol {
          width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          // color: orange;
          font-size: 18px;
          line-height: 22px;
          font-weight: bold;
          border-right: 1px dashed white;
          position: relative;

          .UCP_Discount_Circle_Top {
            position: absolute;
            background-color: white;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            top: -8px;
            right: -8px;
          }

          .UCP_Discount_Circle_Bottom {
            position: absolute;
            background-color: white;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            bottom: -8px;
            right: -8px;
          }
        }

        .UCP_DiscountItem_InfoCol {
          display: flex;
          flex-direction: column;
          padding: 10px;
          margin-right: 20px;

          .UCP_DiscoutItem_Code {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            margin-bottom: 5px;
          }

          .UCP_Discountitem_Description {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 10px;
          }
        }

        .UCP_DiscountItem_Action {
          position: absolute;
          top: 5px;
          right: 10px;
          cursor: pointer;

          .UCP_DiscountItem_Icon {
            color: white;
          }
        }
      }
    }
  }

  .COP_Action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .COP_Action_Btn {
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #e2e2e2;
    color: black;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .COP_Row_Action_Icon {
      margin-right: 5px;
    }

    .COP_Action_Icon {
      margin-right: 5px;
    }
  }

  .COP_Action_Btn:hover {
    opacity: 0.8;
  }
}

.ModalSelectProductz {
  width: 700px;
  // overflow: scroll;

  .product-panel {
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-bottom: 0px !important;
    display: flex;

    .APL_ProductItem {
      background-color: white;
      margin: 5px;
      border: 1px solid #f2f2f2;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      .APL_ProductImage {
        height: 150px;
        width: 100%;
        object-fit: cover;
        border-bottom: 1px solid #f2f2f2;
      }

      .APL_ProductContent {
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color: #e2e2e2;
        // background-color: #f5f6fb;

        .APL_ProductName {
          color: black;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .APL_ProductPrice {
          color: gray;
          font-size: 13px;
          line-height: 17px;

          .APL_OldPrice {
            margin-right: 10px;
            text-decoration: line-through;
          }

          .APL_NewPrice {
            color: black;
            font-size: 15px;
            line-height: 19px;
            font-weight: 600;
          }
        }
      }

      .APL_ProgressFlashSale {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        margin-bottom: 5px;
        justify-content: center;
        align-content: center;

        .APL_ProgreesBarPanel {
          width: 90%;
          margin-right: 10px;
          background-color: rgb(253, 218, 200);
          border-radius: 30px;
          padding: 1px;
          padding-left: 3px;
          padding-right: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 0;
          text-align: center;

          .APL_PercentLabel {
            z-index: 2;
            font-weight: 600;
            color: white;
          }

          .APL_ProgreesBar {
            background-color: rgb(253, 110, 35);
            z-index: 1;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 30px;
          }
        }
      }

      .APL_CountDown {
        color: black;
      }

      .APL_ProductAction {
        display: none;
        position: absolute;
        // display: flex;
        top: 150px;
        width: 100%;
        // background-color: pink;
        align-items: center;
        justify-content: center;
        // left: 35%;

        .APL_ProductActionPanel {
          background-color: white;
          margin: 5px;
          height: 50px;
          width: 50px;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid #eeeeee;
          box-shadow: -2px -2px 2px 2px rgba(0, 0, 0, 0.16),
            -2px -2px 2px 2px rgba(0, 0, 0, 0.16);

          .APL_ProductActionIcon {
            color: black;
            font-size: 16px;
          }
        }
      }

      .APL_ProdcutDiscount {
        position: absolute;
        display: flex;
        justify-content: center;

        top: 5px;
        left: 5px;

        color: white;
        width: 40px;
        height: 36px;

        font-size: 14px;
        line-height: 18px;
        font-weight: 500;

        padding-bottom: 4px;
        padding-top: 5px;
        margin: 1px 0px 0px;
        background-size: 40px 36px !important;
        background-position: 0px 0px;
        background-image: url(https://res.cloudinary.com/dungfly98/image/upload/v1664758009/Component_62_2_sjsbdp.png);
      }
    }

    .APL_ProductItem:hover {
      .APL_ProductAction {
        display: flex;
      }
    }
  }

  .MSPZ_Action {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;

    .MSPZ_Title {
      color: black;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }

    .APL_SearchBoxPanel {
      cursor: pointer;
      margin-right: 10px;
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      align-items: center;

      .APL_SearchBox {
        background-color: #f2f2f2;
        padding: 8px;
        padding-left: 15px;
        padding-right: 30px;
        border-radius: 3px;
        font-weight: 600;
        border: none;
        min-width: 200px;
        border-radius: 4px;
        margin-bottom: 5px;
      }

      .APL_SearchBoxIcon {
        position: absolute;
        top: 12px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
}

.ModalSelectUser_COP {
  width: 450px;
  // overflow: scroll;
  // height: 400px;

  .MSU_ListUsers {
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;

    .MSU_User_Row {
      display: flex;
      flex-direction: row;
      // width: 100%;
      background-color: #f2f2f2;
      margin-bottom: 5px;
      padding: 5px;
      align-items: center;
      position: relative;
      margin-right: 10px;
      border-radius: 3px;

      .MSU_ImageView {
        margin-right: 5px;
        margin-left: 15px;

        .MSU_UserAvatar {
          height: 46px;
          width: 46px;
          border-radius: 23px;
          object-fit: cover;
          border: 1px solid #c2c2c2;
        }
      }

      .MSU_UserName {
        color: black;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
      }

      .MSU_Action {
        position: absolute;
        right: 15px;
        background-color: #074f51;
        color: white;
        padding: 5px 10px;
        border-radius: 2px;
        top: 5px;
        cursor: pointer;
      }

      .MSU_Action:hover {
        opacity: 0.8;
      }
    }
  }

  .MSU_Action {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;

    .MSU_Title {
      color: black;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }

    .APL_SearchBoxPanel {
      cursor: pointer;
      margin-right: 10px;
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      align-items: center;

      .APL_SearchBox {
        background-color: #f2f2f2;
        padding: 8px;
        padding-left: 15px;
        padding-right: 30px;
        border-radius: 3px;
        font-weight: 600;
        border: none;
        min-width: 200px;
        border-radius: 4px;
        margin-bottom: 5px;
      }

      .APL_SearchBoxIcon {
        position: absolute;
        top: 12px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
}
