.DetailPostPage {
    color: black;
    background-color: white;
    padding-top: 20px;

    // img{
    //     height: 300px;
    // }

    .Title {
        // margin-top: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-left: 15px;
    }

    .Time {
        color: gray;
        margin-bottom: 5px;
        margin-left: 15px;
        font-size: 13px;
    }

    .SubTile {
        margin-left: 15px;
        font-weight: bold;
    }

    .Content {
        color: #333;
        line-height: 20px;
        font-size: 18px;
        div {
            margin-bottom: 5px;
        }

        p {
            margin-top: 10px !important;
            // margin-bottom: 3px !important;
        }

        hr {
            margin-left: 15px;
            margin-top: 0;
        }
    }

    .comment_panel {
        margin-left: 15px;
        margin-bottom: 30px;
        margin-top: 20px;
        flex: 1;
        // background-color: pink;

        .Header {
            font-weight: bold;
            font-size: 17px;
            margin-bottom: 20px;
        }

        .input_comment_panel {
            display: flex;
            flex-direction: row;
            // align-items: center;
            margin-bottom: 3px;
            margin-left: 20px;
            white-space: pre-wrap;

            .avatar {
                height: 70px;
                widows: 70px;
                border-radius: 35px;
                margin-right: 10px;
            }

            .input {
                padding-left: 5px;
                padding-top: 5px;
                flex: 1;
                // margin-right: 20px;
                height: 120px;

                text-align: left;
                justify-content: flex-start;
                align-items: flex-start;

                border-color: #888;
                border-radius: 8px;
            }
        }

        .btnSend {
            margin-top: 10px;
            border-radius: 3px;

            cursor: pointer;

            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;

            background-color: #0099e6;
            color: white;
            justify-content: center;
            align-items: center;
            display: flex;

            cursor: pointer;

            height: 40px;
            align-self: flex-end;
        }

        .list_comment {

            .coment_panel {
                display: flex;
                flex-direction: row;
                // align-items: center;
                margin-bottom: 8px;
                margin-left: 20px;
                white-space: pre-wrap;

                .avatar {
                    height: 70px;
                    widows: 70px;
                    border-radius: 35px;
                    margin-right: 10px;
                }

                .comment_content {
                    flex: 1;
                    color: #555;
                    // border-bottom: 1px solid #e2e2e2;
                    // padding-bottom: 20px;

                    .name {
                        font-weight: bold;
                        margin-bottom: 6px;
                        color: black;
                        font-size: 16px;
                        margin-top: 10px;
                    }
                }
            }

            .btnMore {
                border-radius: 3px;

                cursor: pointer;

                // position: absolute;
                // bottom: 5px;
                // right: 5px;

                padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 14px;

                background-color: #e2e2e2;
                color: black;
                justify-content: center;
                align-items: center;
                display: flex;

                cursor: pointer;

                height: 40px;
                align-self: flex-end;
            }
        }
    }

    .relation_post {
        margin-left: 15px;

        .Header {
            font-weight: bold;
            font-size: 17px;
            margin-bottom: 10px;
        }

        .ListNews {
            display: flex;
            flex-direction: column;

            .News {
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                cursor: pointer;

                .thumb {
                    height: 140px;
                    width: 220px;
                    object-fit: cover;
                    margin-right: 10px;
                }

                .InfoView {
                    flex: 1;
                    margin-right: 20px;
                    max-width: 520px;

                    .TitleInfo {
                        font-size: 18px;
                        font-weight: bold;
                        color: black;
                        margin-bottom: 3px;
                    }

                    .TimeInfo {
                        color: gray;
                        font-size: 12px;
                        margin-bottom: 10px;
                    }

                    .Subtitle {
                        color: gray;
                        font-size: 14px;
                    }
                }

            }
        }
    }
}

.tag-header {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
}

.ql-tags {
    padding: 12px 15px;
}

.detail-post-back {
    padding: 12px 15px;
}

.tag-client {
    padding: 0px !important;
}

.post-img-header {
    width: 100%;
    height: 384px;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .DetailPostPage {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media only screen and (min-width: 992px) {
    .DetailPostPage {
        width: 66.66666667%;
    }

}