.FlashSaleDetailPage {
    .FSDP_Actions {
        margin-bottom: 10px;
        .FSDP_ButtonBack {
            background-color: #ffffff;
            color: #000000;
            font-weight: 600;
            padding: 10px;
        }
        .FSDP_ButtonAdd {
            padding: 10px;
            margin-right: 10px;
        }
        .FSDP_ButtonDeleteAll {
            background-color: #ffa500;
        }
    }
    .FSDP_Table_Action_Edit {
        margin-right: 10px;
    }
    .FSDP_Table_Action_Delete {
        background-color: #ffa500;
    }
}

.ModalUpdateProductFlashSale {
    .MUPFS_Label {
        text-transform: uppercase;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        text-align: center;
        margin-bottom: 8px;
    }
    .MUPFSF_Label {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-top: 10px;
    }
    .MUPFSF_Value {
        padding: 6px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        width: 100%;
        font-size: 14px;
    }
    .MUPFSF_Action {
        margin-top: 14px;
    }
    .MUPFSF_Button_Save {
        margin-right: 10px;
    }
    .MUPFSF_Button_Cancel {
        background-color: #ffa500;
    }
}