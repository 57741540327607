.detail-post-admin {
    background-color: #ffffff;
}

.detail-title {
    color: #000000;
    margin-left: 15px;
}

.detail-time {
    margin-left: 15px;
}

.button-back {
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
}

        .Button:hover{
            background-color: #2288ee;
            opacity: 1;
            // color: black;
        }

.chip-tag-container {
    border-bottom: 1px solid;
}
.chip-tag {
    display: inline-block;
    border: 1px solid;
    padding: 6px;
    border-radius: 18px;
    margin-right: 8px;
    margin-bottom: 10px;
    &-label {
        padding-right: 8px;
    }
}

.tag-input {
    border: none;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1 1;
    color: black;
    width: 100%;
    margin-bottom: 10px;
}

.tag-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3399ff;
    opacity: 1; /* Firefox */
  }
  
.tag-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #3399ff;
}
  
.tag-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #3399ff;
}

.tooltip-detail-post {
    margin-top: 10px;
    margin-right: 10px;
}