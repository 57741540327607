.hot-discount-header {
    display: none;
}
// mobile css
@media only screen and (max-width: 600px) {
    .hot-discount-header {
        display: flex;
        margin-top: 10px;
        margin-bottom: 5px;
        .HomePage_Title {
            color: #074f51;
        }
    }
}