.COP_Row_DiscountCode {
  border: none;
  background-color: #f2f2f2;
  padding: 5px;
  color: black;
  width: 200px;
  font-weight: 550;
}

.UCP_DiscountItem {
  :hover {
    cursor: pointer;
  }
  display: flex;
  background-color: #074f51;
  flex-direction: row;
  // padding: 15px;
  // height: 70px;
  border-radius: 6px;
  color: white;
  position: relative;

  .UCP_DiscountItem_PercentCol {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    // color: orange;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    border-right: 1px dashed white;
    position: relative;

    .UCP_Discount_Circle_Top {
      position: absolute;
      background-color: white;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      top: -8px;
      right: -8px;
    }

    .UCP_Discount_Circle_Bottom {
      position: absolute;
      background-color: white;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      bottom: -8px;
      right: -8px;
    }
  }

  .UCP_DiscountItem_InfoCol {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-right: 20px;

    .UCP_DiscoutItem_Code {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 5px;
    }

    .UCP_Discountitem_Description {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 10px;
    }
  }

  .UCP_DiscountItem_Action {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;

    .UCP_DiscountItem_Icon {
      color: white;
    }
  }
}

.ManagerListLevelUser {
  .MLLU_Actions {
    display: flex;
    margin-bottom: 10px;
    .MLLU_AddButton {
      padding: 10px;
      margin-right: 10px;
    }
  }
  .MLLU_Button_Edit {
    margin-right: 10px;
  }
  .MLLU_Button_Delete {
    background-color: #fc0000;
  }
  .MLLU_RelationshipButton {
    background-color: #ffa500;
  }
}

.ModalAddLevelUser {
  .MALU_Label {
    text-transform: uppercase;
    font-weight: 600;
    color: #000000;
    font-size: 18px;
    text-align: center;
    margin-bottom: 8px;
  }
  .MALUF_Label {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-top: 10px;
  }
  .MALUF_Value {
    padding: 6px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    font-size: 14px;
  }

  .MALUF_Value_Number {
    width: auto;
  }

  .MALUF_Select {
    width: 100%;
  }

  .MALUF_Action {
    margin-top: 14px;
  }

  .MALUF_Button {
    margin-right: 8px;
  }
  .MALUF_Button_Save {
    background-color: #ffa500;
  }

  .MALUF_Button_Cancel {
    background-color: #ffa500;
  }
  .MALUF_Space {
    margin: 0 4px;
    font-weight: 600;
    color: #000000;
  }
}

.APL_Table_Action {
  cursor: pointer;
  margin-right: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-weight: 600;

  .APL_Table_Icon {
    font-size: 14px;
    margin-right: 5px;
  }
}

.APL_Table_Action:hover {
  // background-color: #e2e2e2;
  opacity: 0.9;
}
