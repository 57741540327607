.Pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    

    .active .page-link{ 
        background-color: #0099ff !important;
        color: white !important;
        z-index: 0 !important;
        border-radius: 50%;
    }

    .page-link{
        border-radius: 50%;
        color: black;
        font-weight: 400;
        background-color: white;
    }

    .page-link-control{
        // background-color: gray;
        border: 1px solid #e2e2e2;
        color: black;
        padding: 6px 8px 6px 8px;
        border-radius: 50% !important; 
        font-weight: 400;
        background-color: white;
    }

    .page-link-control:hover{
        background-color: #f2f2f2;
    }

    .pagination{
        margin: 10px;

        .page-item{
            // background-color: pink;
            margin: 5px;
            // background-color: #f2f2f2;
        }

        
    }
}