.UserAddressShippingPage{
    background-color: #f2f2f2;
    flex: 1;
    display: flex;
    flex-direction: column;

    .UAS_Title{
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin-top: 10px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .USA_Button{
        // flex: 1;
        background-color: white;
        // margin-top: 10px;
        padding: 15px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
        // border: 1px dashed #e2e2e2;

        .USA_Icon{
            margin-right: 10px;
        }
    }

    .USA_ListAddresss{
        // flex: 1;

        .USA_Address{
            background-color: white;
            margin-bottom: 10px;
            padding: 15px;
            position: relative;
            // border: 1px dashed #e2e2e2;

            .USA_Address_Name{
                color: black;
                text-transform: uppercase;
                display: flex;
                flex-direction: row;
                // background-color: pink;
                padding-top: 5px;
                padding-bottom: 5px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;

                .USA_DefaultAddress{
                    margin-left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: rgb(38, 188, 78);;
                    text-transform: none;
                    // font-weight: 600;

                    .USA_Address_Icon{
                        margin-right: 5px;
                        
                    }
                }
            }

            .USA_Address_RowInfo{
                margin-bottom: 5px;
                color: black;

                .USA_Address_Label{
                    color: gray;
                    margin-right: 5px;
                }

            }

            .USA_Address_Action{
                position: absolute;
                right: 10px;
                top: 20px;
                display: flex;
                flex-direction: row;

                .USA_Action_Edit{
                    cursor: pointer;
                    color: rgb(27, 168, 255);
                    font-size: 13px;
                    line-height: 16px;
                }

                .USA_Action_Remove{
                    margin-left: 10px;
                    cursor: pointer;
                    color: rgb(255, 66, 78);
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
    }
}

@media(max-width: 400px){
    .USA_Address{
        padding: 5px !important;

        .USA_Address_Action{
            top: 10px !important;
        }
    }
}