.ModalConfirmAction{ 
    // background-color: green;
    // width: 400px !important;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    // width: 300px;

    .ImagePanel{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .image{
            height: 60px;
            width: 60px;
            border-radius: 30px;
            background-color: white;
        }
    }

    .QuesionPanel{
        margin-top: 15px;
        // font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        color: black;

        .title{
            color: black;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom: 0px;
        }
    }

    .ControlPanel{
        display: flex;
        flex-direction: row-reverse;
        margin-top: 10px;
        justify-content: center;
        align-items: center;

        .Button{
            margin-right: 10px;
            background-color: #3399ff;
            color: white;
            padding: 5px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .icon{
                margin-right: 5px;
            }
        }

        .Button:hover{
            background-color: #2288ee;
        }
    }

}

.ReactModal__Overlay  {
    z-index: 2;
}