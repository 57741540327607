.MainAdminContent{
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    
    // overflow: hidden;

    .AdminTopBar{
        height: 70px;
        background-color: #074f51;
        // color: white;
    }
    
    .AdContent{
        flex: 1;
        display: flex;
        flex-direction: row;
        position: relative;
        // margin-top: 70px;

        .AdMainView{
            flex: 1;
            width: 100%;
            padding-bottom: 20px;
            // background-color: green;
            background-color: #f2f2f2;
            padding: 5px;
            display: flex;
            // height: calc(100vh - 70px);
            overflow-x: hidden;
            overflow-y: auto;
            // overflow-x: visible;

            .Card{
                // background-color: white;
                flex: 1;
                // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.001);
                padding: 10px;
            }
        }
    }
}