.AdminPostsPage {
    // padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-height: 88vh;

    .HeaderBar {
        margin-top: 5px;
        

        .ControlPanel {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // margin-bottom: 5px;
            margin-top: 10px;

            .Button {
                background-color: #074f51;
                color: white !important;
                padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                margin-right: 5px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .Icon {
                    margin-right: 5px;
                    align-self: center;
                    margin-bottom: 3px;
                }
            }

            .Button:hover{
                // background-color: #2288ee;
                opacity: 0.8;
                // color: black;
            }

            .SearchBox {
                position: relative;

                input {
                    background-color: white;
                    padding: 6px;
                    padding-left: 10px;
                    padding-right: 30px;
                    border: 0px solid gray;
                    border-radius: 6px;
                    min-width: 280px;
                }

                input:focus {
                    outline: none;
                }

                .Icon {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    cursor: pointer;
                }
            }

            .ItemPerPage{
                
                
                .Combobox{
                    width: 200px;
                    margin-right: 10px;
                    padding: 0px;
                    // padding-left: 10px;
                    border-radius: 6px;

                    option{
                        background-color: #f2f2f2;
                    }
                }
            }

        }

        .FilterPanel {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            .SelectCategory {
                margin-right: 30px;
                // margin-left: 10px;

                .title {
                    margin-right: 10px;
                    font-weight: bold;
                }

                .Select {
                    width: 200px;
                }
            }

            .Button {
                background-color: #3399ff;
                color: white;
                padding: 5px;
                padding-left: 10px;
                padding-right: 20px;
                margin-right: 5px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .Icon {
                    margin-right: 10px;
                    align-self: center;
                    margin-bottom: 3px;
                }
            }

            .Button:hover{
                background-color: #2288ee;
                opacity: 1;
                // color: black;
            }
        }
    }

    .ListPosts {
        margin-top: 10px;

        .col-lg-2, .col-md-4, .col-sm-6, .col-12{
            padding: 10px !important;
            border-radius: 6px !important;
        }

        .Post {
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.011);
            padding-bottom: 10px;
            position: relative;
            // margin: 5px;
            height: 380px;
            overflow: hidden;

            .Post_Thumb {
                width: 100%;
                height: 20vh;
                object-fit: cover;
                border-bottom: 1px solid #e2e2e2;
            }

            .Post_Title {
                color: black;
                margin-top: 5px;
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                padding-left: 10px;
                padding-right: 10px;
                overflow: hidden;
                // position: absolute;
                // bottom: 15px;
            }

            .Post_SubTitle{
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 5px;
                overflow: hidden;
                height: 90px;
                color: black;
            }

            .Control{
                position: absolute;
                top: 10px;
                right: 10px;
                // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.011);
                // background-color: white;
                padding-top: 5px;
                padding-bottom: 5px;

                .Icon{
                    color: white;
                    font-size: 23px;
                    cursor: pointer;
                    // border: 1px solid black;
                }

               
            }
        }
    }
}

.ToolTipControlItem{
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.11);
    width: 150px;

    .Row{
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;
        padding: 2px;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        color: black;
        font-size: 15px;

        .Icon{
            margin-right: 5px;
            
            display: flex;
            align-self: center;
        }
    }

    .Row:hover{
        background-color: #2288ee;
        color: white;
    }
}

.header-select {
    margin-right: 10px !important;
}