.xt_dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
}

.xt_dropdown_content {
    display: none;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10001 !important;
    left: 0px;
    top: 20px;
    background-color: white;
    min-width: 150px;
}

.xt_dropdown:hover{

    .xt_dropdown_content{
        display: flex;
        flex-direction: column;
    }
}

.xt_dropdown_content:hover{
    display: flex;
    flex-direction: column;
}
