.UIF_Row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  // justify-content: center;
  align-items: center;

  .UIF_RowTitle {
    width: 150px;
    color: black;
    font-size: 13px;
    line-height: 17px;
    font-weight: 550;
  }

  .UIF_InputPanel {
    flex: 1;
    display: flex;
    max-width: 450px;
    width: 400px;

    // .UIF_RadioPanel {
    // }

    .UFI_Button {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px 8px 15px;
      background-color: #074f51;
      color: white;
      cursor: pointer;
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 3px;

      .UIF_Icon {
        margin-right: 8px;
        font-size: 15px;
      }
    }

    .UFI_Button:hover {
      background-color: #116eca;
    }

    .UIF_Input {
      border: 1px solid #cccccc;
      // background-color: #e2e2e2;
      padding: 6px;
      padding-left: 10px;
      padding-right: 10px;
      flex: 1;
      color: black;
    }

    .UIF_Avatar {
      height: 74px;
      width: 74px;
      border-radius: 37px;
      margin-bottom: 10px;
      border: 1px solid #e2e2e2;
      object-fit: cover;
      // margin-left: 40px;
    }

    .UIF_ButtonUpload {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px 6px 10px;
      background-color: gray;
      color: white;
      cursor: pointer;
      font-size: 13px;
      border-radius: 3px;

      .UIF_Icon {
        margin-right: 8px;
        font-size: 15px;
      }
    }

    .UIF_ButtonUpload:hover {
      background-color: #777777;
    }
  }

  .UIF_TypeAddress {
    flex-direction: row;
  }
}
