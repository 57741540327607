.product-skelecton {
    background: linear-gradient(90deg,hsla(0,0%,74.5%,.2) 25%,hsla(0,0%,50.6%,.24) 37%,hsla(0,0%,74.5%,.2) 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    &-text {
        height: 16px;
        width: 100%;
    }
    &-img {
        width: 100%;
        height: 220px;
    }
}

.mt-8px {
    margin-top: 8px;
}

.mt-18px {
    margin-top: 18px;
}

// mobile css
@media only screen and (max-width: 600px) {
    .product-skelecton {
        &-img {
            height: 107px;
        }
    }
}