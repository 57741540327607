.AdminCustomerGroupPage {
    flex: 1;

    padding-top: 10px;
    padding-bottom: 10px;
    // padding-left: 50px;
    // padding-right: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .APL_FilterPanel {
        display: flex;
        flex-direction: row;
        margin-right: -5px;
        margin-top: 5px;
        padding: 0px;
        justify-content: space-between;
        margin-bottom: 5px;
        // align-items: center;

        .filterPanel {
            display: flex;
            align-items: center;
        }

        .APL_ButtonDisplay {
            background-color: white;
            margin-right: 5px;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            color: black;
            cursor: pointer;

            ._Icon {
                font-size: 16px;
                margin: 0px !important;
            }
        }

        .APL_ButtonAdd {
            background-color: #074f51;
            color: white;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 10px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 4px;
            cursor: pointer;
            // margin-left: 5px;

            font-size: 13px;
            line-height: 17px;
            font-weight: 600;

            .APL_ButtonAddIcon{
                margin-right: 5px;
                
            }
        }

        .APL_ButtonAdd:hover{
            background-color: #074f51;
        }

        .active {
            background-color: #074f51 !important;
            color: white !important;
        }

        .FilterSearchAndSort {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            .APL_SearchBoxPanel {
                cursor: pointer;
                margin-right: 10px;
                color: black;
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: relative;
                align-items: center;

                .APL_SearchBox {
                    background-color: white;
                    padding: 8px;
                    padding-left: 15px;
                    padding-right: 30px;
                    border-radius: 3px;
                    font-weight: 600;
                    border: none;
                    min-width: 200px;
                    border-radius: 4px;
                }

                .APL_SearchBoxIcon {
                    position: absolute;
                    top: 12px;
                    right: 10px;
                    cursor: pointer;
                }
            }

            .APL_ButtonAction {
                background-color: #074f51;
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: none;
                padding: 8px;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 5px;

                font-size: 13px;
                line-height: 17px;
                font-weight: 600;
            }
        }
    }

    .APL_Table_Action {
        cursor: pointer;
        margin-right: 10px;
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        padding: 6px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 3px;
        font-weight: 600;

        .APL_Table_Icon {
            font-size: 14px;
            margin-right: 5px;
        }
    }
}

.ACG_ModalCurrentCustomerGroup{
    // padding: 5px;
    display: flex;
    flex-direction: column;

    .ACGM_Title{
        text-align: center;
        margin-bottom: 10px;
        color: black;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }

    .ACGM_RowInfo{
        width: 330px;
        display: flex;
        margin-bottom: 5px;
        flex-direction: row;
        align-items: center;

        .ACGM_RowTitle{
            width: 70px;
            margin-right: 5px;
            color: black;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
        }

        .ACGM_RowContent{
            flex: 1;
            display: flex;
            flex-direction: column;

            .ACGM_RowContent_Input{
                // width: 100%;
                border: none;
                padding: 5px;
                background-color: #f2f2f2;
                color: black;
            }
        }
    }

    .ACGM_RowInfo_Action{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .ACGM_Buttonz{
            margin-right: 5px;
            padding: 5px 10px;
            background-color: #074f51;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            margin-top: 5px;
            cursor: pointer;

            .ACGM_Buttonz_Icon{
                margin-right: 5px;
            }

        }

        .ACGM_Buttonz:hover{
            opacity: 80%;
            // background-color: #074f51;
        }
    }
}