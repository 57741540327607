.NameBrand {
    color: white;
    font-size: 30px;
    margin-left: 15px;
    // font-weight: bold;
}

@media (max-width: 400px) {
    .NameBrand {
        font-size: 20px;
        margin-left: 0px;
    }
}

.Utop_DropDownContent {
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    padding-top: 10px;
    border-radius: 5px;
    width: 200px;

    .UTD_Info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        .UTD_Avatar {
            height: 60px;
            width: 60px;
            border-radius: 30px;
            border: 1px solid #e2e2e2;
            display: flex !important;
            align-self: center;
        }

        .UTD_FullName {
            margin-top: 10px;
            color: black;
            font-size: 14px;
            font-weight: bold;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #d2d2d2;
        }
    }

    .UTD_ActionRow {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 10px;
        align-items: center;
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 15px;
        color: black;

        .UTD_Icon {
            font-size: 15px;
            margin-right: 5px;
            height: 100%;
            margin-top: 0px;
            margin-bottom: 3px;
        }
    }

    .UTD_ActionRow:hover {
        background-color: #70db70;
    }
}

.Utop_DropDownContent::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    top: 0px;
    left: 15px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
}

.menu {
    .menu-item {
        .menu-item-main {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .menu-item-icon {
                margin-right: 7px;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.mobile-menu-container {
    background-color: #ffffff !important;
}
.mobile-menu-close {
    font-size: 2.5rem !important;
    right: 0 !important;
    top: 0 !important;
    color: #000000 !important;
}

.category-drawer-container {
    margin-top: 37px;
}

.category-drawer-item {
    display: flex;
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    height: 54px;
    align-items: center;
}
.category-drawer-item-top {
    font-size: 14px;
    color: #074f51;
    margin-bottom: 14px;
    & > a {
        color: #074f51;
        margin-left: 9px;
        font-weight: bold;
    }
    & > a:active, :hover {
        color: #074f51 !important;
    }
}



// mobile css
@media only screen and (max-width: 600px) {
// filter product
    .UFP_FilterPanel {
    background-color: white;
    .search-header {
        display: none !important;
    }
    .UFP_PanelTitle {
        color: black;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 5px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .UFP_SearchPanel {
        display: none;
        .UFP_SearchBox {
            display: flex;
            flex: 1;
            flex-direction: row;
            border: none;
            background-color: #f2f2f2;
            padding: 10px;
            color: black;
            border-radius: 4px;
            padding-right: 45px;
            font-weight: 500;
        }

        .UFP_SearchBoxIcon {
            position: absolute;
            right: 15px;
            top: 10px;
            color: black;
            font-size: 16px;
            cursor: pointer;
        }
    }

    .UFP_FilterCategoryPanel {
        margin-bottom: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
        padding-top: 5px;

        .UFP_FilterCategoryItem {
            margin-bottom: 10px;

            .UFP_FilterCategoryItemText {
                color: black;
                font-weight: 500;
            }
        }
    }

    .UFP_FilterByPrice {
        margin-bottom: 15px;
        padding-bottom: 50px;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        flex-direction: column;

        .UFP_EnterPriceFilter {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: black;

            .UFP_PriceInputBox {
                width: 100px;
                margin-right: 10px;
                border: none;
                background-color: #f2f2f2;
                color: black;
                padding: 6px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    .UFP_FilterUnitPanel {
        margin-bottom: 25px;

        .UFP_FilterUnitItem {
            margin-bottom: 10px;
            color: black;
            font-weight: 500;
            padding: 0px;
        }
    }

    .UFP_ButtonFilter{
        position: fixed;
        bottom: 10px;
        width: 270px;
        background-color: #074f51;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;

        .UFP_Icon{
            margin-right: 10px;
        }
    }

    .UFP_ButtonFilter:hover{
        background-color: #00802b;
    }

    .ant-slider-handle {
        border-color: red !important;
    }

    .ant-slider-track {
        background-color: red !important;
    }
}
}

.header-user-drawer {
    display: none;
}
// mobile css
@media only screen and (max-width: 600px) {
    .header-user-drawer {
        display: block;
        .ant-drawer-content-wrapper {
            margin-top: 66px;
            height: calc(100vh - 132px) !important;
        }
        &_title {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
        }
        &_icon-close {
            color: #000000;
            font-size: 18px;
        }
        &_item {
           display: flex;
           align-items: center;
           color: #4E4E4E; 
           font-size: 16px;
           font-weight: bold;
           height: 54px;
           border-bottom: 1px solid #808080;
           &_title {
               margin-left: 11px;
           }
        }
    }

    .xt_dropdown_content {
        display: none !important;
    }
}

// mobile css
@media only screen and (max-width: 479px) {
    .header-search {
        .header-search-wrapper {
            top: -7px !important;
            right: 0px !important;
            width: calc(100vw - 122px) !important;
            &::before {
                display: none !important;
            }
        }
    }
}