.InfoPage {
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;

    .UIF_Title {
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .UIF_Row {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        // justify-content: center;
        align-items: center;

        .UIF_RowTitle {
            width: 150px;
            color: black;
            font-size: 13px;
            line-height: 17px;
            font-weight: 550;
        }

        .UIF_InputPanel {
            flex: 1;
            display: flex;
            max-width: 450px;
            width: 400px;


            // .UIF_RadioPanel {
            // }

            .UFI_Button {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 15px 8px 15px;
                background-color: #074f51;
                color: white;
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;
                border-radius: 3px;

                .UIF_Icon {
                    margin-right: 8px;
                    font-size: 15px;
                }
            }

            .UFI_Button:hover {
                background-color: #116eca;
            }

            .UIF_Input {
                border: 1px solid #cccccc;
                // background-color: #e2e2e2;
                padding: 6px;
                padding-left: 10px;
                padding-right: 10px;
                flex: 1;
                color: black;
            }

            .UIF_Avatar{
                height: 74px;
                width: 74px;
                border-radius: 37px;
                margin-bottom: 10px;
                border: 1px solid #e2e2e2;
                object-fit: cover;
                // margin-left: 40px;
            }

            .UIF_ButtonUpload{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px 10px 6px 10px;
                background-color: gray;
                color: white;
                cursor: pointer;
                font-size: 13px;
                border-radius: 3px;

                .UIF_Icon {
                    margin-right: 8px;
                    font-size: 15px;
                }
            }

            .UIF_ButtonUpload:hover {
                background-color: #777777;
            }
        }

        .UIF_TypeAddress{
            flex-direction: row;
        }
    }
}

@media(max-width: 1000px){
    .InfoPage {
        padding: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media(max-width: 500px){
    .InfoPage {
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .UIF_InputPanel {
        width: 200px !important;
    }

    .UIF_TypeAddress{
        flex-direction: column !important;
    }
}

.UModalRequestChangeCustomerGroup{
    padding: 10px;
    width: 300px;
    display: flex;
    flex-direction: column;

    .UMRG_Title{
        color: black;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }

    .UMRG_RowInfo{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .UMRG_RowTitle{
            width: 80px;
            color: black;
            font-weight: 550;
        }

        .UMRG_RowContent{
            flex: 1;
            display: flex;
            flex-direction: column;
         
            .UMRG_Image{
                width: 100%;
                height: auto;
                margin-bottom: 5px;
                border-radius: 3px;
            }

            .UMRG_TextInput{
                width: 100%;
                border: none;
                height: 60px;
                background-color: #f2f2f2;
                padding: 5px;
            }
        }
    }

    .UMRG_Button{
        background-color: green;
        color: white;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
    }

    .UMRG_Button:hover{
        opacity: 0.9;
    }

    .UMRG_Icon{
        margin-right: 5px;
    }

    .UMRG_Action{
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
    }
}