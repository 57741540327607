@media only screen and (max-width: 600px) {
    .User_Login_Container {
        min-height: 462px!important;
        display: inline!important;
    }
    .User_LoginPage{
        padding-top: 10px!important;
        margin-bottom: 20px;
    }
  }
.User_Login_Container {
    flex: 1;
    min-height: 600px;
    display: flex;
    flex-direction: row;
    background-color:  #f3f3f3;
    justify-content: center;
    align-items: center;
}

.User_LoginPage {
    // min-height: 650px;
    // background-color: #b3e6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url("https://cdn-www.vinid.net/2019/09/Nen-chon-loai-hoa-qua-bieu-Tet-2019-nao.jpg");
    // background-size: cover;

    padding-top: 30px;
    padding-bottom: 30px;

    //responsive login
    @media only screen and (max-width: 600px) {
        .LoginPanel {
            margin-left: 10px;
            margin-right: 10px;
            padding-left: 42px!important;
            padding-right: 42px!important;
            height: 432px!important;
            width: 95%!important; //450px;
        }
        .LGB_More{
            margin-bottom: 0px!important;
        }
      }

    .LoginPanel {
        background-color: white;
        height: 500px;
        width: 350px; //450px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        // border-radius: 10px;

        .LgP_TopPanel {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            border-bottom: 2px solid #e2e2e2;
            margin-bottom: 20px;
            padding-bottom: 15px;

            .LPT_Image {
                height: 80px;
                width: 80px;
                border-radius: 40px;
            }

            .LPT_Title {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 20px;
                color: black;
            }
        }

        .LgP_BottomPanel {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-top: 10px;

            .LBP_Label {
                margin-bottom: 5px;
                color: black;
                // font-weight: bold;
                font-size: 14px;
            }

            .LPB_username {
                margin-bottom: 20px;
                border: none;
                background-color: #e2e2e2;
                padding: 10px;
                border-radius: 3px;
                color: black;
                font-size: 14px;
            }

            .LPB_password {
                margin-bottom: 32px;
                border: none;
                background-color: #e2e2e2;
                padding: 10px;
                border-radius: 3px;
                color: black;
                font-size: 14px;
            }

            .LGB_ButtonLogin {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px 10px 0px;
                background-color: #074f51;
                color: white;
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;
            }

            .LGB_ButtonLogin:hover {
                background-color: #00cc44;
            }

            .LGB_ButtonBackHome{
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px 10px 0px;   
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;
                background-color: #b2b2b2;
                color: black;
                margin-bottom: 20px;
            }

            .LGB_ButtonBackHome:hover {
                background-color: #a2a2a2;
                // color: white;
            }

            .LGB_More {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 15px;
                

                .LGB_Title {
                    color: #3399ff;
                    cursor: pointer;
                }

                .LGB_Title:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media(max-width: 900){
    .LoginPanel{
        width: 300px;
    }
}

.UserLogin_Panel {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 500px;
    background-color: white;
    padding: 15px;
    padding-top: 30px;
    border-left: 1px solid #e2e2e2;
    // margin-left: 10px;

    .ULP_Title {
        color: black;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 5px;
        // margin-left: 30px;
    }

    .ULP_Subtitle {
        color: #222222;
        font-size: 12px;
        margin-bottom: 30px;
    }

    .Row {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: 160px;
        // margin-bottom: 30px;

        .UserLogin_ImagePanel {
            flex: 1;
            // width: 100%;
            // height: 250px;
            object-fit: contain;
            border-radius: 1px;

            // margin: 15px;
        }
    }
}

@media (max-width: 1000px) {
    .UserLogin_Panel {
        display: none;
    }
}

@media(min-width: 1000px) and (max-width: 1300px){
    .UserLogin_Panel{
        width: 550px !important;
        height: 400px !important;
    }

    .LoginPanel{
        height: 400px !important;
    }
}
