.HomePageAdmin {
    display: flex;
    flex-direction: column;
    flex: 1;

    .InfoPage {
        display: flex;
        flex-direction: row;
        flex: 1;

        .InfoItemz {
            // background-color: #b3e6ff !important;
            margin: 10px;
            margin-top: 5px;

            display: flex;
            flex-direction: row;

            .NumberCol {
                width: 90px;
                // background-color: gray;
                display: flex;
                color: white;
                justify-content: center;
                align-items: center;
                height: 90px;
                border-radius: 5px 0px 0px 5px;

                .Icon {
                    height: 35px;
                    width: 35px;
                }
            }

            .InfoCol {
                flex: 1;
                display: flex;
                flex-direction: column;
                color: white;
                // font-weight: bold;
                justify-content: center;
                padding-left: 10px;
                // opacity: 0.6;
                border-radius: 0px 5px 5px 0px;

                .number {
                    font-size: 22px;
                    font-weight: bold;
                }

                .text {
                    font-size: 17px;
                    font-weight: bold;
                }
            }
        }
    }

    .CardChart {
        // background-color: pink;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        padding: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.011);
        background-color: white;
        margin-bottom: 5px;

        .rv-xy-plot,
        .rv-xy-plot__inner {
            display: flex;
            flex: 1;
            width: 100% !important;
        }
    }

    .HPA_RowInfo {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .HPA_PieChart {
            width: 40%;
            background-color: white;
            padding: 10px;
            margin-right: 10px;
        }

        .HPA_ColumnChart {
            flex: 1;
            background-color: white;
            padding: 10px;
        }

        .DashboardInfo {
            display: flex;
            flex-direction: row;
            width: 100%;

            .DBI_Container {
                flex: 1;
                background-color: white;
                padding: 10px;
                margin-right: 10px;
                color: black;
                display: flex;
                flex-direction: row;
                border-radius: 3px;

                .DBI_Logo{
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    background-color: #f2f2f2;
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 15px;

                    .DBI_Icon{
                        font-size: 30px;
                    }
                }

                .DBI_Info{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .DBI_Title{
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    .DBI_Content{
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 450;
                    }
                }
            }
        }
    }
}
