.UserRevenuePage {
    flex: 1;
    background-color: #f2f2f2;
    padding: 10px;
    padding-top: 0px;
    // padding-left: 50px;
    // padding-right: 50px;
    display: flex;
    flex-direction: column;
    // width: 100%;

    .URP_Title {
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        // margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .URP_ButtonDeposit {
        background-color: #074f51;
        color: white;
        padding: 10px 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .Icon {
            margin-right: 5px;
            font-size: 16px;
        }
    }

    .URP_ButtonDeposit:hover {
        background-color: #074f51;
    }

    .RevenueChart {
        margin-bottom: 20px;
        padding: 10px;
        background-color: white;
        // margin-top: 20px;
        // max-height: 400px;
    }

    .col-md-3 {
        margin: 0px !important;
    }

    .URP_BlockRevenuePanel {
        // margin-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 15px;

        .URP_BlockRevenue {
            background-color: white;
            padding: 15px 20px;
            margin: 5px;
            min-width: 95%;
            flex: 1;
            display: flex;
            flex-direction: column;

            .URP_STitle {
                color: black;
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .URP_Subtile {
                color: black;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
            }
        }
    }

    .URP_ListBalance {
        margin-left: 15px;

        .URPB_Title {
            margin-bottom: 10px;

            .URPB_Money {
                font-weight: 600;
                margin-left: 5px;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 5px;
            }
        }
    }

    .URP_ListRevenues {
        .URP_TotalTitle {
            display: flex;
            justify-content: flex-start;
            margin-left: 20px;
            margin-top: 15px;

            .URPR_Money {
                font-weight: 600;
                margin-left: 8px;
                font-size: 14px;
            }
        }
    }

    .URP_ListUser {
        padding: 0px 20px;
    }

    .UHP_PaginateSection {
        display: flex;
        // flex: 1;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        .Pagination {
            justify-content: flex-end;
        }
    }
}

.ModalDepositeOnline {
    width: 250px;
    padding-top: 2px;

    .ModalTitle{
        text-align: center;
        color: black;
        margin-right: 2px;
        font-weight: 550;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
    }

    .RowInfo {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .RowTitle{
            color: black;
            margin-bottom: 3px;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
        }

        .RowContent{
            flex: 1;
            width: 100%;

            .RowInput{
                width: 100%;
                border: none;
                background-color: #f2f2f2;
                color: black;
                font-size: 13px;
                line-height: 17px;
                padding: 7px;
            }

        }
    }

    .ActionInfo {
        .btnDeposit {
            background-color: #074f51;
            color: white;
            padding: 10px 15px;
            margin-bottom: 10px;
            border-radius: 5px;
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;

            .Icon {
                margin-right: 5px;
                font-size: 16px;
            }
        }

        .btnDeposit:hover {
            background-color: #074f51;
        }
    }
}

.table-cds {
    width: 100%;
    tr {
        border-bottom: 1px solid #e2e2e2;
    }
    td, th {
        padding: 15px 5px;
        word-break: break-all;
        text-align: center;
    }
}
