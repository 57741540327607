.UserLevelPage{
    flex: 1;
    background-color: #f2f2f2;
    padding: 10px;
    padding-top: 0px;
    // padding-left: 50px;
    // padding-right: 50px;
    display: flex;
    flex-direction: column;
    
    // width: 100%;

    .ULP_Title {
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        // margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}