.ql-editor {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ql-video {
        $width: 50vw;
        min-width: 300px;
        min-height: 150px;
        width: $width;
        height: $width/16*9;
    }
}
