@font-face {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body {
  // font-family: "Vollkorn" !important;
  // // font-family: 'Noto Serif',serif;
  // #f3f3f3;
  // // font-family: 'Times New Roman', Times, serif;
  // margin: 0;
  // // overflow: scroll;
}

a {
  color: black;
}

#root {
  display: flex;
  flex-direction: column;

  overlay: {
    background: #000000 !important;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.ant-tabs-tab-active:focus,
.ant-tabs-tab:focus {
  outline: none;
}
