.product-panel {
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-bottom: 0px !important;
    display: flex;

    .APL_ProductItem {
        background-color: white;
        margin: 5px;
        border: 1px solid #f2f2f2;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 95%;

        .APL_ProductImage {
            height: 220px;
            width: 100%;
            object-fit: cover;
            border-bottom: 1px solid #f2f2f2;
        }

        .APL_ProductContent {
            padding: 10px;
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // background-color: #e2e2e2;
            // background-color: #f5f6fb;

            .APL_ProductName {
                color: black;
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .APL_ProductPrice {
                color: gray;
                font-size: 13px;
                line-height: 17px;

                .APL_OldPrice{
                    margin-right: 10px;
                    text-decoration: line-through;
                }

                .APL_NewPrice{
                    color: black;
                    font-size: 15px;
                    line-height: 19px;
                    font-weight: 600;
                }
            }
        }

        .APL_ProgressFlashSale{
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            margin-bottom: 5px;
            justify-content: center;
            align-content: center;

            .APL_ProgreesBarPanel{
                width: 90%;
                margin-right: 10px;
                background-color: rgb(253, 218, 200);
                border-radius: 30px;
                padding: 1px;
                padding-left: 3px;
                padding-right: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 0;
                text-align: center;

                .APL_PercentLabel{
                    z-index: 2;
                    font-weight: 600;
                    color: white;
                }

                .APL_ProgreesBar{
                    background-color: rgb(253, 110, 35);
                    z-index: 1;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 30px;
                }
            }

            
        }

        .APL_CountDown{
            color: black;
        }

        .APL_ProductAction {
            display: none;
            position: absolute;
            // display: flex;
            top: 150px;
            width: 100%;
            // background-color: pink;
            align-items: center;
            justify-content: center;
            // left: 35%;

            .APL_ProductActionPanel {
                background-color: white;
                margin: 5px;
                height: 50px;
                width: 50px;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                // border: 1px solid #eeeeee;
                box-shadow: -2px -2px 2px 2px rgba(0, 0, 0, 0.16), -2px -2px 2px 2px rgba(0, 0, 0, 0.16);

                .APL_ProductActionIcon {
                    color: black;
                    font-size: 16px;
                }
            }
        }

        .APL_ProdcutDiscount{
            position: absolute;
            display: flex;
            justify-content: center;

            top: 5px;
            left: 5px;

            color: white;
            width: 40px;
            height: 36px;

            font-size: 14px;
            line-height: 18px;
            font-weight: 500;

            
            padding-bottom: 4px;
            padding-top: 5px;
            margin: 1px 0px 0px;
            background-size: 40px 36px !important;
            background-position: 0px 0px;
            background-image: url(https://res.cloudinary.com/dungfly98/image/upload/v1664758009/Component_62_2_sjsbdp.png);
        }

        .APL_ProdcutPoint {
            position: absolute;
            display: flex;
            justify-content: center;

            top: 5px;
            right: 5px;

            color: white;
            width: 40px;
            height: 36px;

            font-size: 14px;
            line-height: 18px;
            font-weight: 500;

            padding-bottom: 4px;
            padding-top: 5px;
            margin: 1px 0px 0px;
            background-size: 40px 36px !important;
            background-position: 0px 0px;
            background-image: url(https://res.cloudinary.com/dungfly98/image/upload/v1664758009/Component_62_2_sjsbdp.png);
        }
    }

    .APL_ProductItem:hover {
        .APL_ProductAction {
            display: flex;
        }
    }
}

.HomePage_Title{
    color: black;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    
    background-color: white;
    padding: 5px;
    padding: 15px;
    padding-left: 15px;
    width: 250px;
    border-left: 4px solid #074f51;
}