@media only screen and (max-width: 600px) {
    .UserView_RegisterPage {
        min-height: 770px!important;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px!important;
        padding-bottom: 20px!important;
    }
  }
.UserView_RegisterPage{
    flex: 1;
    min-height: 800px;
    display: flex;
    flex-direction: row;
    background-color:  #f3f3f3;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 50px;

    @media only screen and (max-width: 600px) {
        .UserView_ForgotPasswordPanel {
            width: 100%;
        }
      }

    .UserView_ForgotPasswordPanel{
        background-color: white;
        display: flex;
        flex-direction: row;
        margin: 10px;
        overflow: visible;

        @media only screen and (max-width: 600px) {
            .UFG_InfoPanel {
                height: 841px;
                padding-left: 42px!important;
                padding-right: 42px!important;
            }
            .UFIF_BtnResetPassword {
                margin-top: 32px!important;
                margin-bottom: 11px!important;
            }
            .UFIF_More{
                text-align: start!important;
            }
          }

        .UFG_InfoPanel{
            height: 100%;
            padding-left: 30px;
            padding-right: 15px;
            width: 350px;
            padding-top: 10px;
            padding-bottom: 0px;
            display: flex;
            flex-direction: column;

            .LgP_TopPanel {
                margin-bottom: 15px;
                flex-direction: row;
                text-align: center;
                align-items: center;
                display: flex;
                justify-content: center;
                border-bottom: 2px solid #e2e2e2;

    
                .LPT_Image {
                    height: 80px;
                    width: 80px;
                    border-radius: 40px;
                }
    
                .LPT_Title {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 20px;
                    color: black;
                }
            }

            .UFIF_Title{
                margin-top: 25px;
                margin-bottom: 15px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                color: black;
                font-weight: bold;
                font-size: 18px;
            }

            .UFIF_SubTile{
                margin-bottom: 15px;
                color: #333333;
                font-size: 14px;
                text-align: center;
            }

            .UFIF_TitleRow{
                color: black;
                margin-bottom: 8px;
                // font-weight: bold;
                font-size: 14px;
            }

            .UFIF_Input{
                padding: 6px 20px 6px 20px;
                border: none;
                background-color: #e8e8e8;
                margin-bottom: 20px;
                color: black;
                border-radius: 3px;
            }

            .UFIF_BtnResetPassword {
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px 10px 0px;
                background-color: #074f51;
                color: white;
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;
            }

            .UFIF_BtnResetPassword:hover {
                background-color: #0080ff;
            }

            .UFIF_More{
                color: black;
                text-align: center;
            }
            .UFIF_Title_MORE {
                color: #3399ff;
                cursor: pointer;
            }

        }

        .UFG_ImagePanel{
            width: 750px;
            height: 755px !important;
            // height: 100% !important;
            object-fit: contain;
            border-left: 1px solid #e2e2e2;
            margin-left: 15px;
            padding: 15px;
            align-self: center;
            justify-self: center;
        }
        .UFGI_Image{
            width: 750px;
            height: 100% !important;
            object-fit: contain;
            margin-left: 15px;
            padding: 15px;
            align-self: center;
            justify-self: center;
        }
    }
}
