.AddProductFlashSalePage {
    .APFSP_Actions {
        margin-bottom: 10px;
        .APFSP_ButtonBack {
            background-color: #ffffff;
            color: #000000;
            font-weight: 600;
            padding: 10px;
        }
        .APFSP_ButtonAdd {
            padding: 10px;
            margin-right: 10px;
        }
    }
    .APFSP_Input {
        padding: 6px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        font-size: 14px;
    }

    .APFSP_Pagination {
        .Pagination {
            justify-content: flex-end;
        }
    }
}

.FilterSearchAndSort {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .APL_SearchBoxPanel {
        cursor: pointer;
        margin-right: 10px;
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        align-items: center;

        .APL_SearchBox {
            background-color: white;
            padding: 8px;
            padding-left: 15px;
            padding-right: 30px;
            border-radius: 3px;
            font-weight: 600;
            border: none;
            min-width: 200px;
            border-radius: 4px;
        }

        .APL_SearchBoxIcon {
            position: absolute;
            top: 12px;
            right: 10px;
            cursor: pointer;
        }
    }

    .APL_ButtonAction {
        background-color: #074f51;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 8px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 5px;

        font-size: 13px;
        line-height: 17px;
        font-weight: 600;
    }
}