.warehouse {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding-right: 10px;
    .item-header {
      margin-left: 10px;
    }
  }
}
