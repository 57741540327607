@media only screen and (max-width: 600px) {
  .UserCartPage {
    padding-top: 10px !important;
    .UCP_CartCheckOut {
      .UCP_ListCartItems {
        .UCP_CartItem {
          .UCP_CartItem_Info {
            flex-direction: column;
            justify-content: space-between;
          }
          .UCP_CartItem_Total {
            display: none;
          }
          .UCP_CartItem_NamePanel {
            flex: none !important;
            display: flex;
            .UCP_CartItem_Name {
              flex: 1;
            }
            .UCP_CartItem_ActionPanel {
              display: none !important;
            }
            .UCP_CartItem_Price {
              display: none !important;
            }
          }
          .UCP_CartItem_Quantity {
            height: 19px !important;
            .UCP_CartItem_QuantityTextBox {
              width: 25px !important;
              padding-left: 0px !important;
              padding-right: 0px !important;
            }
            .UCP_CartItem_ButtonUp {
              width: 16px !important;
            }
            .UCP_CartItem_ButtonDown {
              width: 16px !important;
            }
          }
        }
      }
    }
  }
  .cart-item-price-mobile {
    font-size: 18px;
    font-weight: 700;
    color: #074f51;
    display: block !important;
  }
  .cart-item-remove {
    display: block !important;
    color: #000;
    font-size: 12px;
  }
}

.cart-item-price-mobile {
  display: none;
}
.cart-item-remove {
  display: none;
}

.UserCartPage {
  flex: 1;
  background-color: #f2f2f2;
  margin: 10px;
  display: flex;
  flex-direction: column;
  // width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  @media only screen and (max-width: 600px) {
    .UCP_Title {
      font-size: 18px !important;
      font-weight: bold !important;
      text-transform: uppercase;
      margin-bottom: 24px !important;
    }
  }
  .UCP_Title {
    color: black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    // margin-left: 20px;
    margin-bottom: 10px;
    margin-right: 20px;

    .UCP_Subtile {
      color: gray;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      margin-left: 5px;
    }
  }

  .UCP_CartEmpty {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    // margin: 20px;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;

    .UCP_ImageEmpty {
      width: 320px;
      height: 240px;
      object-fit: contain;
      // background-color: pink;
    }

    .UCP_Empty_Label {
      color: black;
      font-size: 14px;
      line-height: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
    }

    .UCP_Empty_Button {
      background-color: #e2e2e2;
      padding: 10px;
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 3px;
      background-color: orange;
      color: white;
      font-size: 13px;
      line-height: 17px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .UCP_CartCheckOut {
    display: flex;
    flex-direction: row;

    .UCP_ListCartItems {
      background-color: white;
      flex: 1;
      margin-right: 10px;
      padding: 15px;
      padding-bottom: 0px;

      .UCP_CartItem {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
        .UCP_CartItem_Info {
          display: flex;
          flex: 1;
        }
        .UCP_CartItem_Image {
          height: 80px;
          width: 80px;
          margin-right: 10px;
          border: 1px solid #f2f2f2;
          object-fit: cover;
        }

        .UCP_CartItem_NamePanel {
          flex: 1;

          .UCP_CartItem_Name {
            margin-bottom: 5px;
            color: black;
            font-size: 14px;
            line-height: 18px;
            font-weight: 550;
          }

          .UCP_CartItem_Price {
            margin-bottom: 10px;
          }

          .UCP_CartItem_ActionPanel {
            display: flex;
            flex-direction: row;

            .UCP_CartItem_Action {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .UCP_CartItem_Total {
          margin-right: 20px;
          color: black;
          font-size: 14px;
          line-height: 18px;
          font-weight: 550;
          text-align: right;
          margin-top: 8px;
        }

        .UCP_CartItem_Quantity {
          display: flex;
          flex-direction: row;
          height: 30px;

          .UCP_CartItem_ButtonDown {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: #f2f2f2;
            color: gray;
            font-size: 12px;
            cursor: pointer;
            border: 1px solid #d2d2d2;
            border-right: none;
          }

          .UCP_CartItem_QuantityTextBox {
            // margin-right: 5px;
            border: 1px solid #d2d2d2;
            background-color: white;
            color: gray;
            padding: 10px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
          }

          .UCP_CartItem_ButtonUp {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: #f2f2f2;
            color: gray;
            font-size: 12px;
            border: 1px solid #d2d2d2;
            cursor: pointer;
            border-left: none;
          }
        }
      }
    }

    .UCP_CheckOutInfo {
      display: flex;
      flex-direction: column;
      width: 300px;

      .UCP_InfoPanel {
        background-color: white;
        margin-bottom: 10px;
        padding: 15px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        color: black;
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;

        .UCP_TotalLabel {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .UCP_LeftAlignText {
            margin-right: 20px;
            align-items: flex-end;
          }

          .UCP_RightAlignText {
            flex: 1;
            text-align: right;
          }
        }
      }

      .UCP_DiscountCodePanel {
        align-items: center;
        justify-content: center;
        background-color: white;
        margin-bottom: 10px;
        padding: 15px;

        .UCP_DiscountLabel {
          color: black;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
          font-weight: 600;
        }
        .UCP_DiscountPanelContent {
          align-self: center;
          justify-content: center;
          @media screen and (max-width: 600px) {
            .UCP_ApplyDiscountCode {
              width: 90% !important;
              margin-left: 5%;
              margin-right: 5%;
            }
          }
          .UCP_EnterCodePanel {
            margin-bottom: 10px;
            display: flex;
            width: 100%;
            border: none;
            background-color: #f2f2f2;
            padding: 10px;
            color: black;
            font-size: 13px;
            line-height: 17px;
          }

          .UCP_ApplyDiscountCode {
            background-color: #d2d2d2;
            color: black;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;
            cursor: pointer;
            width: 100%;
            border-radius: 3px;
          }

          .UCP_ApplyDiscountCode:hover {
            background-color: #c1c1c1;
          }
        }

        .UCP_DiscountItem {
          display: flex;
          background-color: #074f51;
          flex-direction: row;
          // padding: 15px;
          // height: 70px;
          border-radius: 6px;
          color: white;
          position: relative;

          .UCP_DiscountItem_PercentCol {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            // color: orange;
            font-size: 18px;
            line-height: 22px;
            font-weight: bold;
            border-right: 1px dashed white;
            position: relative;

            .UCP_Discount_Circle_Top {
              position: absolute;
              background-color: white;
              height: 16px;
              width: 16px;
              border-radius: 8px;
              top: -8px;
              right: -8px;
            }

            .UCP_Discount_Circle_Bottom {
              position: absolute;
              background-color: white;
              height: 16px;
              width: 16px;
              border-radius: 8px;
              bottom: -8px;
              right: -8px;
            }
          }

          .UCP_DiscountItem_InfoCol {
            display: flex;
            flex-direction: column;
            padding: 10px;

            .UCP_DiscoutItem_Code {
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
              margin-bottom: 5px;
            }

            .UCP_Discountitem_Description {
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              margin-bottom: 10px;
            }
          }

          .UCP_DiscountItem_Action {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;

            .UCP_DiscountItem_Icon {
              color: white;
            }
          }
        }
      }

      .UCP_PointPanel {
        background-color: white;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        justify-content: center;

        .UCPP_Row {
          display: flex;
          flex-direction: row;
          align-self: center;
          justify-content: center;
          margin-top: 17px;
          margin-bottom: 17px;
          .UCPP_Row_Title {
            color: black;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            width: 120px;
            display: flex;
            align-items: center;
          }

          .UCPP_RowContent {
            flex: 1;
            color: black;

            .UCPP_RowText {
              margin-bottom: 10px;
              display: flex;
              width: 100%;
              border: none;
              background-color: #f2f2f2;
              padding: 10px;
              color: black;
              font-size: 13px;
              line-height: 17px;
            }
          }
          @media screen and (max-width: 600px) {
            .UCPP_Button {
              width: 90% !important;
            }
          }
          .UCPP_Button {
            background-color: #d2d2d2;
            color: black;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;
            cursor: pointer;
            border-radius: 3px;
            width: 100%;
          }

          .UCPP_Button:hover {
            background-color: #c1c1c1;
          }
        }

        .UCP_DiscountPanelContent {
          .UCP_EnterCodePanel {
            margin-bottom: 10px;
            display: flex;
            width: 100%;
            border: none;
            background-color: #f2f2f2;
            padding: 10px;
            color: black;
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .UCP_OrderButton {
          background-color: #074f51 !important;
          position: fixed;
          bottom: 10%;
          right: 10%;
          width: 80%;
          z-index: 1024;
        }
      }
      .UCP_OrderButton {
        background-color: #074f51;
        color: white;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
        line-height: 17px;
        cursor: pointer;
        margin-bottom: 15px;
        border-radius: 3px;
      }

      .UCP_OrderButton:hover {
        background-color: #074f51;
      }
    }
  }

  .UCP_AddressShipping {
    background-color: white;
    padding: 20px;
    color: black;
    margin-bottom: 10px;
    border-radius: 3px;
    position: relative;

    .UCP_AddressShipping_Title {
      color: black;
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      // justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 18px;

      .UCP_Icon {
        margin-right: 5px;
      }
    }

    .UCP_AddressContent {
      color: gray;
    }

    .UCP_Action {
      position: absolute;
      right: 25px;
      top: 20px;
      text-decoration: underline;
      cursor: pointer;

      font-weight: 600;
    }
    @media screen and (max-width: 992px) {
      .USA_Address {
        padding-top: 10px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }
    }
    .USA_Address {
      background-color: #f5f5f5;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      margin-bottom: 8px;
      // border: 1px dashed #e2e2e2;

      .USA_Address_Name {
        color: black;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        // background-color: pink;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        .USA_DefaultAddress {
          margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(38, 188, 78);
          text-transform: none;
          // font-weight: 600;

          .USA_Address_Icon {
            margin-right: 5px;
          }
        }
      }

      .USA_Address_RowInfo {
        margin-bottom: 5px;
        color: black;

        .USA_Address_Label {
          color: gray;
          margin-right: 5px;
        }
      }
    }
    .USA_Address_Action {
      // position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      flex-direction: row;

      .USA_Action_Edit {
        cursor: pointer;
        color: rgb(27, 168, 255);
        font-size: 13px;
        line-height: 16px;
      }

      .USA_Action_Remove {
        margin-left: 10px;
        cursor: pointer;
        color: rgb(255, 66, 78);
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .UCP_SelectContainer {
    // display: flex;

    .UCP_SelectRow {
      background-color: white;
      padding: 20px;
      color: black;
      margin-bottom: 10px;
      border-radius: 3px;
      position: relative;

      .UCP_SelectTitle {
        color: black;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        // justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 18px;

        .Icon {
          margin-right: 5px;
        }
      }

      .UCP_SelectContent {
        color: black;
      }
    }
  }
}

.UCP_ModalSelectAddress {
  .USA_Address {
    background-color: #f5f5f5;
    margin-bottom: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    // border: 1px dashed #e2e2e2;

    .USA_Address_Name {
      color: black;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      // background-color: pink;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      .USA_DefaultAddress {
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(38, 188, 78);
        text-transform: none;
        // font-weight: 600;

        .USA_Address_Icon {
          margin-right: 5px;
        }
      }
    }

    .USA_Address_RowInfo {
      margin-bottom: 5px;
      color: black;

      .USA_Address_Label {
        color: gray;
        margin-right: 5px;
      }
    }

    .USA_Address_Action {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      flex-direction: row;

      .USA_Action_Edit {
        cursor: pointer;
        color: rgb(27, 168, 255);
        font-size: 13px;
        line-height: 16px;
      }

      .USA_Action_Remove {
        margin-left: 10px;
        cursor: pointer;
        color: rgb(255, 66, 78);
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .USA_ButtonZ {
    // flex: 1;
    background-color: #f5f5f5;
    // margin-top: 10px;
    padding: 10px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    // margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    font-weight: 600;
    border: 1px dashed lightgray;

    .USA_Icon {
      margin-right: 10px;
    }
  }
}

@media (max-width: 990px) {
  .container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media (max-width: 600px) {
  .UCP_CartCheckOut {
    flex-direction: column !important;
  }

  .UCP_ListCartItems {
    width: 100% !important;
  }

  .UCP_CheckOutInfo {
    margin-top: 10px;
    width: 100% !important;
  }
}
