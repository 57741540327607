.UChangePassword {
    flex: 1;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    // min-height: 600px;

    .UCP_Row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .UCP_Title {
            width: 140px;
            display: flex;
            color: black;
            font-size: 13px;
            line-height: 17px;
            font-weight: 500;
        }

        .UCP_InputPanel {
            flex: 1;
            display: flex;
            max-width: 350px;

            .inputPanel-group {
                width: 100%;
            }
            .UCP_Text {
                border: 1px solid #cccccc;
                // background-color: #e2e2e2;
                padding: 6px;
                padding-left: 10px;
                padding-right: 10px;
                flex: 1;
                color: black;
                width: 100%;
            }

            .UCP_Button {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 15px 8px 15px;
                background-color: #074f51;
                color: white;
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;

                .UCP_Icon {
                    margin-right: 5px;
                }
            }

            .UCP_Button:hover {
                background-color: #0080ff;
            }
        }
    }
}

.error-input {
    color: #ff0000;
}

.disable-button-change {
    background-color: #074f5185 !important;
}

@media(max-width: 1000px){
    .UChangePassword {
        padding: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}