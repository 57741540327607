.ToolTipComponentMainView {
    
    .ToolTipComponent {
        .Content {
            position: absolute;
            top: 30px;
            right: 0px;
            background-color: transparent;
            z-index: 1 !important;

            padding: 10px;
            border-radius: 5px;
            display: flex;
        }
    }
}
