.AdminUserDetail {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;

    .APD_MainContent {
        display: flex;
        flex-direction: row;

        padding: 0px 10px;
        margin: 0px 10px;

        img {
            // border: 1px solid #e2e2e2;
        }

        .APD_Title {
            color: black;
            font-weight: 600;
            line-height: 18px;
            font-size: 14px;
            margin-top: 10px;
            // margin-bottom: 5px;
        }

        .APD_InfoPanel {
            flex: 1;
            margin-top: 10px;
            margin-left: 150px;
            margin-right: 150px;
            // background-color: pink;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;

            .APD_InfoRow {
                padding-top: 7px;
                padding-bottom: 7px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .APD_InfoTitle {
                    color: black;
                    font-size: 13px;
                    line-height: 17px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    width: 150px;
                    // justify-self: flex-start;
                    align-self: flex-start;
                    margin-top: 10px;
                }

                .APD_InfoContent {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    .APD_Value {
                        border: none;
                        background-color: #f2f2f2;
                        min-width: 100%;
                        padding: 10px;
                        border-radius: 3px;
                        color: black;
                    }
                }

                .APD_InfoImageContent{
                    display: flex;
                    flex-direction: row;
                    background-color: #f2f2f2;
                    // width: 100%;
                    width: 100%;
                    padding: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                    border-radius: 3px;
                    

                    .APD_ProductImageItem{
                        position: relative;
                        background-color:white;
                        height: 100px;
                        width: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        display: flex;
                        flex-direction: column;

                        margin: 10px;
                        margin-right: 0px;

                        cursor: pointer;

                        border-radius: 3px;

                        .APD_ProductImage{
                            height: 100px;
                            width: 100px;
                            object-fit: cover;
                        }

                        .APD_ProductImage_RemoveButton{
                            color: red;
                            position: absolute;
                            top: 5px;
                            right: 5px;
                        }

                        .APD_UploadProductImage{
                            font-size: 20px;
                            color: black;
                        }

                        .APD_ProductImage_Title{
                            margin-top: 10px;
                            color: black;
                        }
                    }

                    .APD_ProductImageItem:hover{
                        background-color: lightgray;
                        opacity: 0.9;

                    }
                }

                .APD_BtnUploadAvatar{
                    background-color: #074f51;
                    padding: 5px;
                    padding-left: 10px;
                    padding-right: 10px;
                    color: white;
                    border-radius: 2px;

                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;

                    cursor: pointer;
                    margin-right: 10px;

                    .Icon {
                        margin-right: 5px;
                    }
                }

                .APD_BtnUploadAvatar:hover {
                    // background-color: #074f51;
                    opacity: 0.9 !important;
                }

            }
        }
    }

    .APD_ActionPanel {
        display: flex;
        justify-content: center;
        align-items: center;

        .APD_ActionButton {
            background-color: #074f51;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            color: white;
            border-radius: 6px;

            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            cursor: pointer;
            margin-right: 10px;

            .APD_ActionButtonIcon {
                margin-right: 5px;
            }
        }

        .APD_ActionButton:hover {
            // background-color: #074f51;
            opacity: 0.9 !important;
        }
    }
}

@media(max-width:1000px){
    .APD_InfoPanel{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}

@media(max-width:600px){
    .APD_InfoPanel{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .APD_InfoRow{
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}

@media(max-width: 400px){
    .APD_ProductImageItem{
        height: 85px !important;
        width: 85px !important;

        .APD_ProductImage{
            height: 85px !important;
            width: 85px !important;
        }
    }
}
