.LinkAffiliatePage {
    flex: 1;
    padding: 30px;

    .LAF_Title {
        color: black;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    .LAF_Subtile {
        color: black;
        font-size: 13px;
        line-height: 16px;
    }

    .LAF_LinkPanel {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .LAF_LinkG {
            display: flex;
            flex-direction: row;
            // margin-right: 10px;

            .LAF_Link {
                flex: 1;
                padding: 10px;
                padding-left: 20px;
                padding-right: 50px;
                // padding-right: 5px;
                background-color: #e8e8e8;
                position: relative;
                color: black;
                border-radius: 5px;
                font-size: 13px;
                line-height: 16px;

                .LAF_Icon {
                    position: absolute;
                    top: 25%;
                    right: 10px;
                    font-size: 20px;
                    color: black;
                    cursor: pointer;
                }
            }
        }

        .LAF_Share {
            // margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;

            .LAF_Iconz {
                margin-right: 3px;
                font-size: 30px;
                color: black;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 500px) {

    .LinkAffiliatePage{
        padding-left: 10px;
        padding-right: 10px;

        .LAF_Link{
            font-size: 12px !important;
            line-height: 16px;
        }
    }

    .LAF_LinkPanel {
        flex-direction: column !important;
    }

    .LAF_Share{
        margin-top: 10px;
    }
}
