.ManagerListLevelUser {
  .MLLU_Actions {
    display: flex;
    margin-bottom: 10px;
    .MLLU_AddButton {
      padding: 10px;
      margin-right: 10px;
    }
  }
  .MLLU_Button_Edit {
    margin-right: 10px;
  }
  .MLLU_Button_Delete {
    background-color: #fc0000;
  }
  .MLLU_RelationshipButton {
    background-color: #ffa500;
  }
}

.ModalAddLevelUser {
  .MALU_Label {
    text-transform: uppercase;
    font-weight: 600;
    color: #000000;
    font-size: 18px;
    text-align: center;
    margin-bottom: 8px;
  }
  .MALUF_Label {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-top: 10px;
  }
  .MALUF_Value {
    padding: 6px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    font-size: 14px;
  }

  .MALUF_Value_Number {
    width: auto;
  }

  .MALUF_Select {
    width: 100%;
  }

  .MALUF_Action {
    margin-top: 14px;
  }

  .MALUF_Button {
    margin-right: 8px;
  }
  .MALUF_Button_Save {
    background-color: #ffa500;
  }

  .MALUF_Button_Cancel {
    background-color: #ffa500;
  }
  .MALUF_Space {
    margin: 0 4px;
    font-weight: 600;
    color: #000000;
  }
}

.APL_Table_Action {
  cursor: pointer;
  margin-right: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-weight: 600;

  .APL_Table_Icon {
    font-size: 14px;
    margin-right: 5px;
  }
}

.APL_Table_Action:hover {
  // background-color: #e2e2e2;
  opacity: 0.9;
}
