.ACG_CreateVoucher {
  // padding: 5px;
  background-color: white;
  height: "100%";
  padding: 20px;

  .ACGM_Title {
    text-align: center;
    margin-bottom: 20px;
    color: black;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .ACGM_RowInfo {
    width: 800px;
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    flex-direction: row;
    align-items: center;

    .ACGM_RowTitle {
      width: 250px;
      margin-right: 5px;
      color: black;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    }

    .ACGM_RowContent {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ACGM_RowContent_Input {
        // width: 100%;
        border: none;
        padding: 5px;
        background-color: #f2f2f2;
        color: black;
        min-height: 40px;
      }
    }
  }

  .ACGM_RowInfo_Action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .ACGM_Buttonz {
      margin-right: 5px;
      padding: 5px 10px;
      background-color: #074f51;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      margin-top: 5px;
      cursor: pointer;

      .ACGM_Buttonz_Icon {
        margin-right: 5px;
      }
    }

    .ACGM_Buttonz:hover {
      opacity: 80%;
      // background-color: #074f51;
    }
  }
}

.invalid {
  border: 1px solid #ff0000 !important;
}
