.HomePage {
    .TopMenu {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        margin-bottom: 10px;
        // margin-left: 0px !important;

        .LeftMenu {
            width: 280px;
            // margin-left: 15px;
            margin-right: 15px;
            background-color: white;

            .MenuT {
                // border: 1px solid #e2e2e2;

                .MenuItem {
                    margin: 5px;
                    border-bottom: 1px solid #e2e2e2;
                    padding: 15px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: black;

                    .Icon {
                        margin-right: 15px;
                        // color: #555555;
                        font-size: 20px;
                        // margin-bottom: 4px;
                    }

                    .MenuItem_Title {
                        font-size: 14px;
                        // color: #555555;
                        font-weight: bold;
                    }
                }

                .MenuItem:hover {
                    background-color: #074f51;
                    color: white;
                }
            }
        }

        .PanelCenter {
            flex: 1;
            position: relative !important;

            .Center_Image {
                height: 450px;
                width: 100%;
                object-fit: cover;
                border-radius: 4px;
            }

            .Center_ArrowLeft {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                height: 40px;
                width: 40px;
                border-radius: 20px;
                background-color: white;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border: 1px solid #f2f2f2;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                cursor: pointer;
                // border-radius: 2px;
                color: black;

                .Icon {
                    font-size: 16px;
                }
            }

            .Center_ArrowRight {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                height: 40px;
                width: 40px;
                border-radius: 20px;
                background-color: white;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border: 1px solid #f2f2f2;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                cursor: pointer;
                // border-radius: 2px;
                color: black;

                .Icon {
                    font-size: 16px;
                }
            }

            .Center_ArrowLeft:hover,
            .Center_ArrowRight:hover {
                background-color: #f2f2f2;
                color: black;
            }
        }

        .RightMenu {
            display: flex;
            flex-direction: column;
            width: 250px;
            text-align: center;
            margin-left: 15px;
            // border: 1px solid #e2e2e2;
            align-items: center;
            background-color: white;

            .RightMenu_Title {
                margin-top: 25px;
                color: black;
                font-weight: bold;
                font-size: 20px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .RightMenu_flashsale-name {
                color: black;
                font-weight: bold;
                font-size: 20px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .RightMenu_ImageProduct {
                width: 70%;
                height: 200px;
                object-fit: cover;
                margin-bottom: 10px;
            }

            .RightMenu_ProductName {
                margin-bottom: 10px;
                color: #222222;
                font-size: 16px;
            }

            .RightMenu_OldPrice {
                margin-bottom: 3px;
                text-decoration: line-through;
                color: gray;
                font-size: 12px;
            }

            .RightMenu_NewPrice {
                margin-bottom: 45px;
                color: black;
                font-size: 16px;
            }

            .RightMenu_Info {
                color: black;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    @media (max-width: 500px) {
        .TopMenu {
            // display: none;
            flex-wrap: wrap;
            flex-direction: column;
            .LeftMenu{
                width: 100%;
                margin-right: 0px;
                order: 1;
            }

            .PanelCenter{
                order: 0;
                position: relative;
                flex: none;
                margin-bottom: 10px;
                & > img {
                    height: 220px !important;
                }
            }

            .RightMenu{
                display: none;
            }
        }
    }

    @media (max-width: 1200px) {
        .TopMenu {
            // display: none;

            .LeftMenu{

            }

            .PanelCenter{
            }

            .RightMenu{
                display: none;
            }
        }
    }

    .HomePage_Title{
        color: black;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        text-transform: uppercase;
        
        background-color: white;
        padding: 5px;
        padding: 15px;
        padding-left: 15px;
        // padding-right: 35px;
        width: 250px;
        border-left: 4px solid #074f51;
    }

    .product-panel {
        margin-left: -5px !important;
        margin-right: -5px !important;
        margin-bottom: 0px !important;
        display: flex;

        .APL_ProductItem {
            background-color: white;
            margin: 5px;
            border: 1px solid #f2f2f2;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            height: 95%;

            .APL_ProductImage {
                height: 220px;
                width: 100%;
                object-fit: cover;
                border-bottom: 1px solid #f2f2f2;
            }

            .APL_ProductContent {
                padding: 10px;
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // background-color: #e2e2e2;
                // background-color: #f5f6fb;

                .APL_ProductName {
                    color: black;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .APL_ProductPrice {
                    color: gray;
                    font-size: 13px;
                    line-height: 17px;

                    .APL_OldPrice{
                        margin-right: 10px;
                        text-decoration: line-through;
                    }

                    .APL_NewPrice{
                        color: black;
                        font-size: 15px;
                        line-height: 19px;
                        font-weight: 600;
                    }
                }
            }

            .APL_ProgressFlashSale{
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-top: 15px;
                margin-bottom: 5px;
                justify-content: center;
                align-content: center;

                .APL_ProgreesBarPanel{
                    width: 90%;
                    margin-right: 10px;
                    background-color: rgb(253, 218, 200);
                    border-radius: 30px;
                    padding: 1px;
                    padding-left: 3px;
                    padding-right: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 0;
                    text-align: center;

                    .APL_PercentLabel{
                        z-index: 2;
                        font-weight: 600;
                        color: white;
                    }

                    .APL_ProgreesBar{
                        background-color: rgb(253, 110, 35);
                        z-index: 1;
                        position: absolute;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 30px;
                    }
                }

                
            }

            .APL_CountDown{
                color: black;
            }

            .APL_ProductAction {
                display: none;
                position: absolute;
                // display: flex;
                top: 150px;
                width: 100%;
                // background-color: pink;
                align-items: center;
                justify-content: center;
                // left: 35%;

                .APL_ProductActionPanel {
                    background-color: white;
                    margin: 5px;
                    height: 50px;
                    width: 50px;
                    border-radius: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid #eeeeee;
                    box-shadow: -2px -2px 2px 2px rgba(0, 0, 0, 0.16), -2px -2px 2px 2px rgba(0, 0, 0, 0.16);

                    .APL_ProductActionIcon {
                        color: black;
                        font-size: 16px;
                    }
                }
            }

            .APL_ProdcutDiscount{
                position: absolute;
                display: flex;
                justify-content: center;

                top: 5px;
                left: 5px;

                color: white;
                width: 40px;
                height: 36px;

                font-size: 14px;
                line-height: 18px;
                font-weight: 500;

                
                padding-bottom: 4px;
                padding-top: 5px;
                margin: 1px 0px 0px;
                background-size: 40px 36px !important;
                background-position: 0px 0px;
                background-image: url(https://res.cloudinary.com/dungfly98/image/upload/v1664758009/Component_62_2_sjsbdp.png);
            }

            .APL_ProdcutPoint {
                position: absolute;
                display: flex;
                justify-content: center;

                top: 5px;
                right: 5px;

                color: white;
                width: 40px;
                height: 36px;

                font-size: 14px;
                line-height: 18px;
                font-weight: 500;

                padding-bottom: 4px;
                padding-top: 5px;
                margin: 1px 0px 0px;
                background-size: 40px 36px !important;
                background-position: 0px 0px;
                background-image: url(https://res.cloudinary.com/dungfly98/image/upload/v1664758009/Component_62_2_sjsbdp.png);
            }
        }

        .APL_ProductItem:hover {
            .APL_ProductAction {
                display: flex;
            }
        }
    }

    .StoryOfShop {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;

        .TitlePanel {
            display: flex;
            flex-direction: column;
            align-items: center;

            .Title {
                color: black;
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 5px;
            }

            .SubTitle {
                margin-bottom: 10px;
                color: orange;
                font-weight: bold;
                font-size: 30px;
            }
        }

        .SOS_ImagePanel {
            display: flex;
            flex-direction: row;
            // margin-bottom: 10px;
            cursor: pointer;

            .SOS_Panel {
                // flex: 1;
                display: flex;
                flex-direction: column;
                padding: 5px;
                margin: 5px;
                padding-bottom: 10px;
                text-align: center;
                background-color: white;
                margin-bottom: 10px;
                height: 500px;
                overflow: hidden;

                .SOS_Panel_Image {
                    // display: flex;
                    width: 100%;
                    height: 300px;
                    border-bottom: 1px solid #e8e8e8;
                    object-fit: cover;
                }

                .SOS_Panel_Time {
                    color: orange;
                    font-weight: bold;
                    margin-top: 10px;
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                .SOS_Panel_Title {
                    color: black;
                    font-weight: bold;
                    font-size: 18px;
                    max-width: 300px;
                    align-self: center;
                    text-transform: uppercase;
                }

                .SOS_Panel_Line {
                    color: orange;
                    margin-bottom: 5px;
                    font-size: 20px;
                }

                .SOS_Panel_Author {
                    color: gray;
                    font-size: 13px;
                }
            }
        }
    }
}

.btn-view-all {
    color: #074f51;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
}

.btn-view-all__icon {
    background-color: #074f51;
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    justify-self: center;
    align-items: center;
    margin-left: 4px;
    padding: 1px;
}

.load-more {
    display: none;
}

// mobile css
@media only screen and (max-width: 600px) {
    .HomePage {
        .TopMenu {
            .LeftMenu {
                display: none;
            }
        }
        .product-panel {
            .APL_ProductItem {
                height: 92%;
                .APL_ProductImage {
                    height: 107px;
                }
                .APL_ProductContent {
                    padding: 0px;
                    text-align: center;
                    padding: 3px;
                    height: 100%;
                    .APL_ProductName {
                      font-size: 12px;  
                      flex: 1;
                    }
                    .APL_ProductPrice {
                        .APL_NewPrice {
                            font-size: 13px;
                        }
                    }
                }
                &:hover .APL_ProductAction {
                    display: none;
                }
            }
        }
        .HomePage_Title {
            flex: 1;
            text-align: center;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px;
            .stripe-lines {
                display: block;
                height: 30px;
                width: 2px;
                background: #074f51;
                transform: rotate(30deg);
                margin-right: 3px;
                margin-left: 3px;
            }
        }
        .title-line {
            display: none;
        }
        .StoryOfShop {
            .SOS_ImagePanel  {
                .SOS_Panel {
                    height: 95%;
                    padding-bottom: 10px;
                    .SOS_Panel_Image {
                        height: 146px;
                    }
                    .SOS_Panel_Title {
                        font-size: 13px;
                        flex: 1;
                    }
                    .SOS_Panel_Time {
                        font-size: 12px;
                    }
                    .SOS_Panel_Author {
                        font-size: 10px;
                    }
                }
            }
            .TitlePanel {
                .Title {
                    font-size: 18px;
                }
                .SubTitle {
                    font-size: 20px;
                }
            }
        }
        .load-more {
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .btn-view-all {
        display: none !important;
    }

    .service-section {
        display: none !important;
    }
}