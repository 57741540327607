.AdminListUsers {
    flex: 1;

    padding-top: 10px;
    padding-bottom: 10px;
    // padding-left: 50px;
    // padding-right: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .APL_FilterPanel {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        padding: 0px;
        justify-content: space-between;
        // align-items: center;

        .filterPanel {
            display: flex;
            align-items: center;
        }

        .APL_ButtonDisplay {
            background-color: white;
            margin-right: 5px;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            color: black;
            cursor: pointer;

            ._Icon {
                font-size: 16px;
                margin: 0px !important;
            }
        }

        .APL_ButtonAdd {
            background-color: #074f51;
            color: white;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 10px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 4px;
            cursor: pointer;
            // margin-left: 5px;

            font-size: 13px;
            line-height: 17px;
            font-weight: 600;

            .APL_ButtonAddIcon{
                margin-right: 5px;
                
            }
        }

        .APL_ButtonAdd:hover{
            background-color: #074f51;
        }

        .active {
            background-color: #074f51 !important;
            color: white !important;
        }

        .FilterSearchAndSort {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            .APL_SearchBoxPanel {
                cursor: pointer;
                margin-right: 10px;
                color: black;
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: relative;
                align-items: center;

                .APL_SearchBox {
                    background-color: white;
                    padding: 8px;
                    padding-left: 15px;
                    padding-right: 30px;
                    border-radius: 3px;
                    font-weight: 600;
                    border: none;
                    min-width: 200px;
                    border-radius: 4px;
                }

                .APL_SearchBoxIcon {
                    position: absolute;
                    top: 12px;
                    right: 10px;
                    cursor: pointer;
                }
            }

            .dropdown {
                position: relative;
                display: flex;
                flex-direction: column;
            }

            .dropdown-content {
                display: none;
                position: absolute;
                // background-color: #f1f1f1;
                min-width: 250px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 10001 !important;
                right: 0px;
                top: 35px;
                background-color: white;
            }

            .dropdown-content .APL_DropDownItem {
                color: black;
                padding: 12px 16px;
                text-decoration: none;
                display: flex;
                // justify-content: center;
                align-items: center;
                cursor: pointer;

                .APL_Icon {
                    margin-right: 10px;
                }
            }

            .dropdown-content .APL_DropDownItem:hover {
                background-color: #c2f0c2;
            }

            .dropdown:hover .dropdown-content {
                display: flex;
                flex-direction: column;
            }

            .APL_ButtonAction {
                background-color: #074f51;
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: none;
                padding: 8px;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 5px;

                font-size: 13px;
                line-height: 17px;
                font-weight: 600;
            }
        }
    }

    .row {
        padding: 0px !important;
    }

    .APL_Table_Action {
        cursor: pointer;
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        padding: 5px;
        border-radius: 3px;
        font-weight: 600;

        .APL_Table_Icon {
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .APL_Table_Action:hover{
        // background-color: #e2e2e2;
        opacity: 0.8;
    }

    .APL_ProductPanel {
        // padding: 10px;
        margin-left: 5px;
        margin-right: 5px;

        .APL_ProductItem {
            background-color: white;
            margin: 5px;
            border: 1px solid #f2f2f2;
            margin-bottom: 15px;
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;

            .APL_ProductImage {
                height: 220px;
                width: 100%;
                object-fit: cover;
                border-bottom: 1px solid #f2f2f2;
            }

            .APL_ProductContent {
                padding: 10px;
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // background-color: #e2e2e2;
                // background-color: #f5f6fb;

                .APL_ProductName {
                    color: black;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .APL_ProductPrice {
                    color: gray;
                    font-size: 13px;
                    line-height: 17px;
                }
            }

            .APL_ProductAction {
                display: none;
                position: absolute;
                // display: flex;
                top: 150px;
                left: 35%;

                .APL_ProductActionPanel {
                    background-color: white;
                    margin: 5px;
                    height: 30px;
                    width: 30px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid #eeeeee;
                    box-shadow: -2px -2px 2px 2px rgba(0, 0, 0, 0.16), -2px -2px 2px 2px rgba(0, 0, 0, 0.16);

                    .APL_ProductActionIcon {
                        color: black;
                    }
                }
            }
        }

        .APL_ProductItem:hover {
            .APL_ProductAction {
                display: flex;
            }
        }
    }

    .UHB_PaginateSection {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        .Pagination {
            justify-content: flex-end;
        }
    }
}

@media (max-width: 800px) {
    .miniHide {
        display: none !important;
    }
}

@media (max-width: 500px) {
    .superMiniHide {
        display: none !important;
    }

    .APL_Table_Action {
        padding: 5px !important;

        .APL_Table_Icon {
            margin: 0px !important;
        }
    }

    .APL_FilterPanel{
        flex-direction: column !important;

        .FilterSearchAndSort{
            justify-content: space-between;
        }
    
        ._display_page{
            display: none !important;
        }
    }

   
}

.dot-active {
    margin-left: 4px;
    color: #074f51;
}