.ManagerLevelRelationship {
    .MLRS_title {
        text-transform: uppercase;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        margin-bottom: 8px;
    }
    .MLRS_table {
        padding: 20px;
        color: #000000;
        td, th {
            border: 1px solid #000000;
            padding: 1em;
        }
    }
    .MLRS_Actions {
        margin-bottom: 10px;
        .MLR_AddButton {
            padding: 10px;
            margin-right: 10px;
        }
    }
    .MLR_ButtonBack {
        background-color: #ffffff;
        color: #000000;
        padding: 10px;
    }
}