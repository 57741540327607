.DetailBillPage {
    flex: 1;
    background-color: #f2f2f2;
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    // background-color: pink;

    .DBP_BackLinkContainer {
        // margin-top: 10px;
        margin-bottom: 10px;

        .DBP_BackLink {
            background-color: white;
            padding: 10px 20px;
            color: black;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;

            .DBP_BackLinkIcon {
                // margin-right: 5px;
                font-size: 20px;
            }
        }

        .DBP_BackLink:hover {
            // background-color: #c2c2c2;
        }
    }

    .DBP_Title {
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .DPB_Subtitle {
        color: black;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .DBP_Info {
        display: flex;
        flex-direction: row;
        // margin-top: px;
        margin-bottom: 15px;

        .DBP_Info_Container {
            display: flex;
            flex: 1;
            padding: 0px !important;
            margin-top: 15px;

            .DBP_Info_Panel {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-right: 10px;

                .DBP_Info_Title {
                    color: black;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                }

                .DBP_Info_Content {
                    background-color: white;
                    flex: 1;
                    color: black;
                    padding: 15px;

                    .DBP_Info_MainText {
                        color: black;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    .DBP_Info_NormaText {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .DBP_BillContent {
        background-color: white;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;

        .DBP_ListItems {
            display: flex;
            flex-direction: column;
            flex: 1;
            // background-color: pink;

            .DBP_Table_HeaderRow {
                display: flex;
                flex-direction: row;
                // text-align: center;
                padding: 15px 0px 15px 0px;
                border-bottom: 1px solid #e2e2e2;
                // margin-bottom: 10px;

                .DBP_Table_HeaderColumn {
                    color: black;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    // flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .DBP_TableRow {
                display: flex;
                flex-direction: row;
                // text-align: center;
                padding: 8px 0px 8px 0px;
                border-bottom: 1px solid #e2e2e2;
                // margin-bottom: 10px;

                .DBP_Table_Column {
                    color: black;
                    font-weight: 450;
                    font-size: 13px;
                    line-height: 17px;
                    text-align: center;
                    // flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .DBP_Image_Product {
                        height: 60px;
                        width: 60px;
                        margin: 3px;
                        object-fit: cover;
                        border: 1px solid #f2f2f2;
                    }
                }
            }
        }

        .DBP_SumInfo {
            display: flex;
            flex-direction: column;
            // justify-self: flex-end;
            align-items: flex-end;
            margin-bottom: 5px;

            .DBP_SumInfo_Row {
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;

                .DBP_SumInfo_Title {
                    margin-right: 20px;
                    color: #555555;
                    font-size: 13px;
                    line-height: 17px;
                    font-weight: 600;
                }

                .DBP_SumInfo_Value {
                    width: 100px;
                    text-align: right;
                    color: black;
                    font-size: 13px;
                    line-height: 17px;
                    font-weight: 500;
                }
            }
        }

        .DPB_BtnCancel{
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: orange;
            color: white;
            margin-bottom: 10px;
            border-radius: 5px;
            cursor: pointer;

            .DBP_BtnIcon{
                margin-right: 5px;
            }
        }

        .DPB_BtnCancel{
            background-color: #ffaf1a; 
        }
    }
}

@media (max-width: 400px) {
    .DetailBillPage {
        padding: 5px 0px 0px 0px;
        overflow: scroll;

        .DBP_Info {
            flex-direction: column;
        }

        // .DBP_Image_Product {
        //     height: 40px !important;
        //     width: 40px !important;
        //     margin: 3px;
        //     // display: none;
        // }
    }
}
