.AdminCategoryList {
    .ACL_Header {
        margin-bottom: 14px;
    }
    .ACL_body{ 
        background-color: #ffffff;
        padding: 14px;
    }
    .ACL_AddButton {
        background-color: #074f51;
        color: white;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 13px;
        line-height: 17px;
        font-weight: 600;
    }
    .ACL_Tree {
        flex-grow: 1;
        border-right: 1px solid #d9d9d9;
    }
    .ACL_TreeView {
        border: none;
    }
    .ACL_Label {
        text-transform: uppercase;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        text-align: center;
        margin-bottom: 8px;
    }
    .AdminDetailCategory {
        flex-grow: 1;
        padding-left: 14px;
        .ADC_Label {
            text-transform: uppercase;
            font-weight: 600;
            color: #000000;
            font-size: 18px;
            text-align: center;
            margin-bottom: 8px;
        }
        .ADCF_Label {
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            margin-top: 10px;
        }
        .ADCF_Value {
            padding: 6px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            width: 100%;
            font-size: 14px;
        }

        .ADCF_Select {
            width: 100%;
        }

        .ADCF_Action {
            margin-top: 14px;
        }

        .ADCF_Button {
            margin-right: 8px;
            padding: 10px
        }
        .ADCF_Button_Edit {
            background-color: #ffa500;
        }

        .ADCF_Button_Delete {
            background-color: #ff0000;
        }
    }
}

.AdminAddCategoryModal {
    form {
        margin: 0;
    }
    .AACM_Label {
        text-transform: uppercase;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        text-align: center;
        margin-bottom: 8px;
    }
    .AACMF_Label {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-top: 10px;
    }
    .AACMF_Value {
        padding: 6px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        width: 100%;
        font-size: 14px;
    }

    .AACMF_Select {
        width: 100%;
    }

    .AACMF_Action {
        margin-top: 14px;
    }

    .AACMF_Button {
        margin-right: 8px;
    }
    .AACMF_Button_Save {
        background-color: #ffa500;
    }

    .AACMF_Button_Cancel {
        background-color: #ffa500;
    }
}