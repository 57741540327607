.notification-parent{
    margin-top: 20px;
    // // border: 1px solid gray;
    // height: 80px;
    // background-color: white;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // border-left: 5px solid green;
    
    // .notify_container{
    //     width: 100%;
    //     // border-radius: 6px;
        

    //     .success{
    //         color: green;
    //     }

    //     .error{
    //         color: red;
    //     }


        
    // }
}