.LoginPage{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-family: "Vollkorn";
    // background-image: url("../../../../resource/images/background_login.jpg");
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-color: #e2e2e2;

    .LoginPanelz{
        // height: 550px;
        width: 500px;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;

        hr{
            margin: 0;
            color: black;
        }

        .LogoTitle{
            // background-color: pink;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            // justify-content: center;
            align-items: center;

            .Logo{
                height: 130px;
                width: 130px;
                // margin-left: 50px;
            }

            .Info{
                margin-top: 20px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 20px;
            }
        }

        .LoginForm{
            flex: 1;
            margin-left: 30px;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            // margin-bottom: 10px; 

            .Icon{
                font-size: 20px;
                position: absolute;
                left: 20px;
                top: 27px;
                color: gray;
                // color: red;
            }

            .Input{
                margin-top: 15px;
                padding: 15px;
                padding-left: 60px;
                padding-right: 20px;
                background-color: #f2f2f2;
                color: black;
                border: none;
                font-size: 14px;
                border-radius: 6px;
                flex: 1;
            }

            .Input:focus{
                outline: none;
            }

            .Button{
                margin-top: 10px;
                padding: 16px;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 3px;
                text-align: center;
                cursor: pointer;

                // font-weight: bold;
                font-size: 14px;
            }

            .Login{
                background-color: #33cc33;
                color: white;
                margin-top: 20px;
                text-transform: uppercase;
            }

            .Login:hover{
                background-color: #00cc44;
            }

            .Login:active{
                background-color: #33cc33;
                color: white;
            }

            .Register{
                background-color: #3399ff;
                color: white;
                text-transform: uppercase;
            }

            .Register:hover{
                background-color: #0073e6;
            }

            .Register::active{
                background-color: #3399ff;
                color: white;
            }
        }
    }
}