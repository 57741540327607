.PaymentFailPage{
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 15px;    
    
    .PaymentResultForm{
        padding: 30px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        width: 350px;

        .Icon{
            color: red;
            font-size: 70px;
            margin-bottom: 15px;
        }

        .Title{
            color: black;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            text-align: center;
        }

        .Action{
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;

            .PFP_Fail{
                text-decoration: underline;
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }

    
}