.SidebarProfileContainer{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    

    .SPR_AccountInfo{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        margin-bottom: 10px;

        .SPR_Image{
            height: 60px;
            width: 60px;
            border-radius: 30px;  
            margin-right: 15px;
            border: 1px solid #e2e2e2;
        }

        .SPR_UserInfo{
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;

            .SPR_LabelInfo{
                color: #333333;
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 3px;
            }

            .SPR_Name{
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: black;
            }

        }
    }

    .SPR_Menu{
        display: flex;
        flex-direction: column;

        .SPR_MenuItem{
            display: flex;
            flex-direction: row;
            padding: 10px;
            margin-bottom: 5px;
            color: black;
            // justify-content: center;
            align-items: center;
            padding-left: 25px;

            .SPR_Icon{
                font-size: 20px;
                margin-right: 20px;
            }

            .SPR_Title{
                color: black;
                font-size: 14px;
            }
        }
    }

    .SPR_Active {
        background-color: #e2e2e2;
    }
}

@media(max-width: 800px){
    .SidebarProfileContainer{
        display: none;
    }
}