.UserDetailProductPage {
  padding-top: 10px;
  padding-bottom: 10px;

  .UDPP_ProductPanel {
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 10px;

    .UDPP_ImagePanel {
      width: 300px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .UDPP_MainImagePanel {
        .UDDPP_MainImage {
          width: 100%;
          max-width: 300px;
          display: flex;
          object-fit: cover;
          border: 1px solid #e8e8e8;
          cursor: pointer;
        }
      }

      .UDPP_ListImage {
        display: flex;
        margin-top: 10px;

        .UDPP_ListImageIconLeft {
          color: black;
          font-size: 16px;
          font-weight: bold;
          justify-self: center;
          align-self: center;
          margin-right: 5px;
          cursor: pointer;
        }

        .UDPP_ListImageIconRight {
          color: black;
          font-size: 16px;
          font-weight: bold;
          justify-self: center;
          align-self: center;
          margin-left: 5px;
          cursor: pointer;
        }

        .UDPP_ImageItemPanel {
          flex: 1;
          margin-right: 5px;

          .UDPP_ImageItem {
            width: 100%;
            object-fit: cover;
            border: 1px solid #e2e2e2;
            cursor: pointer;
            padding: 5px;
          }
        }
      }
    }

    .UDPP_InfoPanel {
      flex: 1;
      // margin-top: 15px;
      padding: 20px;

      .UDPP_NameProduct {
        color: black;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .UDPP_Subtite {
        color: gray;
        // text-transform: uppercase;
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .UDPP_PriceProduct {
        color: black;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 10px;

        .UDPP_OldPrice {
          margin-right: 10px;
          text-decoration: line-through;
        }

        .UDPP_NewPrice {
          color: black;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
        }

        .UDPP_FlashsaleInfoProduct {
          background-color: #f2f2f2;
          padding: 10px;

          .UDPP_FlashSaleProduct_Info {
            color: black;
            margin-bottom: 10px;
          }

          .UDPP_FlashSaleProduct {
            display: flex;

            .UDDP_FlashSaleProgressBar {
              flex: 1;
              margin-left: 10px;
              justify-self: center;
              align-self: center;
            }
          }
        }
      }

      .UDDP_AddToCartProduct {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .UDDP_QuantityPanel {
          .UDDP_QuantityTitle {
            margin-bottom: 5px;
            color: black;
            font-size: 14px;
            line-height: 18px;
          }
        }

        .UDDP_AddToCartButton {
          width: 250px;
          background-color: #074f51;
          color: white;
          margin-top: 10px;
          margin-left: 15px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          font-size: 14px;
          line-height: 18px;
          // text-transform: uppercase;
          font-weight: 600;
          border-radius: 5px;

          .UDDP_AddToCartButtonIcon {
            margin-right: 10px;
          }
        }

        .UDDP_AddToCartButton:hover {
          background-color: #074f51;
        }
      }

      .UDPP_DescriptionProduct {
        .UDPP_DescriptionProductTitle {
          margin-bottom: 5px;
          color: black;
          font-size: 14px;
          line-height: 18px;
          margin-top: 20px;
          font-weight: 600;
        }

        color: #555555;
      }
    }
  }

  .UDPP_RelationProduct {
    .UDPP_RelationProductTitle {
      color: black;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
      margin-top: 15px;
    }
  }

  .UCP_CartItem_Quantity {
    display: flex;
    flex-direction: row;
    height: 30px;

    .UCP_CartItem_ButtonDown {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #f2f2f2;
      color: gray;
      font-size: 12px;
      cursor: pointer;
      border: 1px solid #d2d2d2;
      border-right: none;
    }

    .UCP_CartItem_QuantityTextBox {
      // margin-right: 5px;
      border: 1px solid #d2d2d2;
      background-color: white;
      color: gray;
      padding: 10px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .UCP_CartItem_ButtonUp {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #f2f2f2;
      color: gray;
      font-size: 12px;
      border: 1px solid #d2d2d2;
      cursor: pointer;
      border-left: none;
    }
  }
}

@media (max-width: 800px) {
  .UDPP_ProductPanel {
    flex-direction: column !important;

    .UDPP_ImagePanel {
      width: calc(100%) !important;
    }
  }
}

.UDPP_PriceProductMobileView {
  display: none;
}

// mobile css
@media only screen and (max-width: 600px) {
  .UserDetailProductPage {
    .UDPP_RelationProduct {
      .UDPP_RelationProductTitle {
        color: black;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        text-transform: uppercase;
        background-color: white;
        padding: 5px;
        padding: 15px;
        padding-left: 15px;
        width: 250px;
        border-left: 4px solid #074f51;
      }
    }
    .UDPP_ProductPanel {
      margin-top: 0px;
      .UDPP_InfoPanel {
        padding: 0px;
        .UDPP_NameProduct {
          font-size: 18px;
        }
        .UDDP_AddToCartProduct {
          flex-wrap: wrap;
          .UDDP_QuantityPanel {
            flex: 1;
          }
          .UDDP_AddToCartButton {
            width: 100%;
            margin-left: 0px;
          }
        }
        .UDPP_DescriptionProduct {
          font-size: 14px;
          color: #808080;
        }
        .UDDP_AddToCartProduct {
          .UDDP_QuantityPanel {
            .UDDP_QuantityTitle {
              display: none;
            }
          }
        }
        .UDPP_PriceProduct {
          display: none;
        }
      }
      .UDPP_ImagePanel {
        .UDPP_MainImagePanel {
          .UDDPP_MainImage {
            max-width: 100%;
          }
        }
        .UDPP_ListImage {
          display: none;
          .UDPP_ImageItemPanel {
            .UDPP_ImageItem {
              height: 100px;
            }
          }
        }
      }
    }
  }

  .UDPP_PriceProductMobileView {
    display: block;
    font-size: 24px;
    font-weight: bold;
    color: #074f51;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
