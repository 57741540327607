.ADP_Title_Page{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
}

.AdminDetailPostPage{
    background-color: white;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.011);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // min-height: 88vh;

    .InfoPanel{
        
        .row{
            margin-top: 10px;

            .Title{
                display: flex;
                color: black;
                font-size: 14px;
                font-weight: bold;
                text-align: right;
                align-items: flex-start;
                justify-content: flex-end;
                height: 100%;
                margin-top: 10px;
            }

            .adminContentComponent{
                display: flex;
                flex-direction: column;
                margin-right: 20px;

                .Content{
                    color: #333;
                    line-height: 20px;
            
                    div{
                        margin-bottom: 5px;
                    }
            
                    p{
                        margin-top: 10px !important;
                        // margin-bottom: 3px !important;
                    }
            
                    hr{
                        margin-left: 15px;
                        margin-top: 0;
                    }
                }

                .Input{
                    width: 100%;
                    background-color: #e8e8e8;
                    border: none;
                    border-radius: 6px;
                    padding: 10px;
                    color: black;
                    font-size: 14px;
                }

                .Input:focus{
                    outline: none;
                }

                .image{
                    margin-top: 10px;
                    width: 200px;
                    border-radius: 2px;
                    border: 1px solid #e8e8e8;
                }
                
                .Button {
                    background-color: #3399ff;
                    color: white;
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-right: 5px;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
    
                    .Icon {
                        margin-right: 5px;
                        align-self: center;
                        margin-bottom: 3px;
                    }
                }

                .Button:hover{
                    background-color: #2288ee;
                    opacity: 1;
                    // color: black;
                }
            }
        }
    }
    
    .list_comment{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding-left: 10%;
         
        .coment_panel{
            display: flex;
            flex-direction: row;
            margin-bottom: 8px;
            margin-left: 5px;
            margin-right: 5px;
            white-space: pre-wrap;
            background-color: white;
            border-bottom: 1px solid #e2e2e2;
            height: 120px;
            overflow: hidden;
            max-width: 600px;

            .avatar{
                height: 80px;
                widows: 80px;
                border-radius: 40px;
                margin-right: 10px;
                margin-left: 5px;
            }

            .comment_content{
                flex: 1;
                color: #555;
                // border-bottom: 1px solid #e2e2e2;
                // padding-bottom: 20px;

                .name{
                    font-weight: bold;
                    margin-bottom: 6px;
                    color: black;
                    font-size: 16px;
                    margin-top: 10px;
                }
            }

            .Control{
                display: flex;
                flex-direction: row;
                margin-top: 15px;
                padding-right: 20px;
                // align-items: center;

                .Button{
                   margin-right: 10px; 
                   font-size: 15px;
                   color: #333333;
                   cursor: pointer;
                }

                .Button:hover{
                    color: black;
                }
            }
        }

        .btnMore{
            border-radius: 3px;

            cursor: pointer;

            // position: absolute;
            // bottom: 5px;
            // right: 5px;

            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;

            background-color: #e2e2e2;
            color: black;
            justify-content: center;
            align-items: center;
            display: flex;

            cursor: pointer;

            height: 40px;
            align-self: flex-end;
        }
    }

    .PaginateSection{
        max-width: 600px;
    }

    .BottomControlPanel{
        padding-bottom: 20px;
        margin-top: 10px;

        .row{
            align-items: center !important;
            justify-content: center !important;
        }

        .Button {
            background-color: #3399ff;
            color: white;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 5px;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .Icon {
                margin-right: 5px;
                align-self: center;
                margin-bottom: 3px;
            }
        }

        .Button:hover{
            background-color: #2288ee;
            opacity: 1;
            // color: black;
        }
    }
}

.chip-tag-container {
    border-bottom: 1px solid;
}
.chip-tag {
    display: inline-block;
    border: 1px solid;
    padding: 6px;
    border-radius: 18px;
    margin-right: 8px;
    margin-bottom: 10px;
    &-label {
        padding-right: 8px;
    }
}

.tag-input {
    border: none;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1 1;
    color: black;
    width: 100%;
    margin-bottom: 10px;
}

.tag-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3399ff;
    opacity: 1; /* Firefox */
  }
  
.tag-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #3399ff;
}
  
.tag-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #3399ff;
}