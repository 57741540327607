.CardPanel {
    // height: 80px;
    // width: 120px;
    background-color: #074f51;
    border: 1px solid #f2f2f2;
    position: fixed;
    flex-direction: column;
    top: 50%;
    right: 0;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    border-radius: 10px 0px 0px 10px;
    z-index: 999;
    cursor: pointer;

    .RowItem {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;

        .Icon {
            font-size: 20px;
        }

        .Title {
            margin-left: 7px;
        }

        .NumberItem {
            position: absolute;
            top: -5px;
            right: -8px;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: red;
            color: white;
            font-size: 12px;
        }
    }

    .ColItem {
        .TotalPrice {
            padding: 5px;
            padding-left: 15px;
            padding-right: 15px;
            background-color: white;
            color: #074f51;
            border-radius: 10px;
            font-weight: bold;

            .Icon {
                display: none;
            }
        }
    }
}

@media (max-width: 600px) {
    .CardPanel {
        // display: none;
        padding: 15px;

        .RowItem {
            display: none;
        }

        .ColItem {
            flex-direction: column;

            .TotalPrice {
                display: flex;
                flex-direction: row;
                font-size: 12px;
                padding: 5px;
                padding-left: 10px;
                padding-right: 10px;

                .Icon {
                    display: flex;
                    font-size: 13px;
                    margin-right: 5px;
                    color: #074f51;
                    margin-bottom: 3px;
                }
            }
        }
    }
}

.CartPopUp {
    background-color: white;
    // border: 1px solid gray;
    position: fixed;
    flex-direction: column;
    top: 20%;
    right: 0;
    padding: 10px;

    display: flex;

    box-shadow: -2px -1px 9px 3px rgba(0, 0, 0, 0.21), -2px -1px 9px 3px rgba(0, 0, 0, 0.21);
    border-radius: 5px;
    // border: 1px solid #c2c2c2;

    z-index: 1000;
    max-height: 70%;
    width: 330px;
    // display: none;

    .CP_Title_Row {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 15px 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #d2d2d2;

        .CPTR_Left {
            color: black;
            font-weight: bold;
            font-size: 14px;
        }

        .CPTR_Right {
            color: black;
            display: flex;
            align-items: center;
            cursor: pointer;

            .CPTR_Icon {
                margin-left: 5px;
                color: red;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    .CP_ListProducts {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        max-height: 40%;
        overflow-y: scroll;

        .CPLP_Item {
            display: flex;
            flex-direction: row;
            margin-bottom: 8px;
            // justify-content: space-between;

            .CPLP_Image {
                height: 75px;
                width: 75px;
                object-fit: contain;
                border: 1px solid #f2f2f2;
                border-radius: 3px;
            }

            .CPLP_InfoPanel {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 15px;
                margin-right: 10px;
                justify-content: center;

                .CPLPI_Name {
                    color: black;
                    font-size: 14px;
                    margin-bottom: 10px;
                    // font-weight: bold;
                }

                .CPLPI_Quantity {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .CPLPI_Button {
                        margin-left: 5px;
                        height: 20px;
                        width: 20px;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // border: 1px solid black;
                        background-color: #074f51;
                        color: white;
                        font-size: 14;
                        font-weight: bold;
                        cursor: pointer;
                    }

                    .CPLPI_Button:hover{
                        background-color: #0080ff;
                    }

                    .CPLPI_Button:active{
                        background-color: #074f51;
                    }


                    .CPLPI_QtyText {
                        width: 50px;
                        // height: 30px;
                        margin-left: 5px;
                        // border-radius: 15px;
                        background-color: #e2e2e2;
                        text-align: center;
                        padding: 6px;
                        border: none;
                        color: black;
                    }
                }
            }

            .CPLP_Total {
                position: relative;

                .CPLPT_Sum {
                    margin-top: 50px;
                }

                .CPLPT_RemoveButton {
                    position: absolute;
                    top: 10px;
                    right: 10px;

                    .CPLPT_Icon {
                        color: red;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .CP_Total {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 5px 10px;
        margin-top: 30px;

        .CPTT_Title {
            color: black;
            // font-weight: bold;
            font-size: 14px;
        }

        .CPTT_Money {
            color: black;
            // font-weight: bold;
            font-size: 14px;
        }
    }

    .CP_ButtonCheckOut {
        padding: 10px;

        .CP_Button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px 10px 0px;
            background-color: #074f51;
            color: white;
        }

        .CP_Button:hover{
            background-color: #0080ff;
        }

        .CP_Button:active{
            background-color: #074f51;
        }
    }
}

@media(min-width: 900px){
    .CartPopUp{
        width: 400px;
    }
}

.cplpi-input-quatity {
    display: flex;
    align-items: center;
}

.mobile-total {
    display: none;
}
.border-item {
    display: none;
}
// mobile css
@media only screen and (max-width: 600px) {
    .CartPopUp {
        .CP_ListProducts {
            display: block;
            .CPLP_Item {
                .CPLP_Image {
                    width: 99px;
                    height: 99px;
                    object-fit: cover;
                }
                .CPLP_InfoPanel {
                    .CPLPI_Name {
                        font-size: 14px;
                        font-weight: bold;
                    }
                    .CPLPI_Quantity {
                        .CPLPI_Button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #f2f2f2;
                            color: gray;
                            font-size: 12px;
                            cursor: pointer;
                            border: 1px solid #d2d2d2;
                            border-radius: 0%;
                            margin: 0px;
                            height: 100%;
                            &:active, &:hover {
                                background-color: #f2f2f2 !important;
                            }
                        }
                        .CPLPI_QtyText {
                            margin: 0px;
                            height: 100%;
                            border: 1px solid #d2d2d2;
                            width: 25px;
                            background-color: #ffffff;
                        }
                    }
                }
                .CPLP_Total {
                    .CPLPT_RemoveButton {
                        top: 0px;
                        right: 0px;
                        font-size: 14px;
                        .CPLPT_Icon {
                            color: #000000;
                        }
                    }
                    .CPLPT_Sum {
                        display: none;
                    }
                }
            }
        }
        .CP_Total {
            align-items: center;
            border-top: 2px solid #808080;
            .CPTT_Title {
                font-size: 14px;
                font-weight: bold;
            }
            .CPTT_Money {
                color: #074f51;
                font-size: 24px;
                font-weight: bold;
            }
        }
        .CP_ButtonCheckOut {
            .CP_Button {
                text-transform: uppercase;
                font-size: 14px;
            }
        }
        .CP_Title_Row {
            .CPTR_Right {
                .CPTR_Icon {
                    color: #000000;
                }
            }
            .CPTR_Left {
                display: none;
            }
            .CPTR_Left-mobile {
                text-transform: uppercase;
                font-size: 18px;
                font-weight: bold;
                color: #000000;
            }        
        }
    }
    .cplpi-input-quatity {
        height: 19px;
        align-items: flex-start;
    }
    .quantity-title {
        display: none;
    }
    .mobile-total {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: #074f51;
        margin-top: 9px;
    }
    .border-item {
        display: block;
        height: 1px;
        background-color: #E9E9E9;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}