.UserView_ForgotPassword{
    flex: 1;
    min-height: 650px;
    display: flex;
    flex-direction: row;
    background-color:  #f3f3f3;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    .UserView_ForgotPasswordPanel{
        background-color: white;
        // height: 500px;
        // width: 350px; //450px;
        display: flex;
        flex-direction: row;
        // padding-left: 20px;
        // padding-right: 20px;
        margin: 50px;


        .UFG_InfoPanel{
            padding-left: 30px;
            padding-right: 15px;
            max-width: 350px;
            min-width: 300px;
            min-height: 350px;
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;

            .UFIF_Title{
                margin-top: 25px;
                margin-bottom: 15px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                color: black;
                font-weight: bold;
                font-size: 18px;
            }

            .UFIF_SubTile{
                margin-bottom: 15px;
                color: #333333;
                font-size: 14px;
                text-align: center;
            }

            .UFIF_RowTitle{
                margin-bottom: 5px;
                color: #333333;
                font-size: 14px;
                font-weight: 600;
                color: black;
            }

            .UFIF_EmailInput{
                padding: 10px;
                border: none;
                background-color: #e2e2e2;
                margin-bottom: 10px;
            }

            .UFIF_BtnResetPassword {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px 10px 0px;
                background-color: #074f51;
                color: white;
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;
            }

            .UFIF_BtnResetPassword:hover {
                background-color: #0080ff;
            }

            .UFIF_More{
                color: black;
                text-align: center;
            }

        }

        .UFG_ImagePanel{
            width: 750px;
            height: 500px !important;
            object-fit: contain;
            border-left: 1px solid #e2e2e2;
            margin-left: 15px;
            padding: 15px;
            // border: 1px solid #f2f2f2;
        }
    }
}

@media(max-width: 1000px){
    .UFG_ImagePanel{
        display: none;
    }

    .UFG_InfoPanel{
        padding-left: 15px !important;
    }
}

@media(min-width: 1000px) and (max-width: 1300px){
    .UFG_ImagePanel{
        width: 550px !important;
        height: 400px !important;
    }
}