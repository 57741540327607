.hr-seperate {
    margin: 16px 0px;
}

.list-post-tags-header {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
}

.list-post-tags {
    background-color: #ffffff;
    padding: 16px;
    color: #000000;
}

.post-card {
    width: 100%;
    margin: 0px !important;
    background-color: #ffffff;
    padding-bottom: 16px;
}

.thumb-post {
    width: 100%;
    height: 220px;
    padding: 0px !important;
    img {
        width: 100%;
        object-fit: cover;
        height: 220;
    }
}

.post-summary {
    padding: 0px !important;
}

.description {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
}

.truncate-overflow {
    --max-lines: 3;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
}
.truncate-overflow::before {
    position: absolute;
    content: "...";
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
}
.truncate-overflow::after {
    content: "";
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
}