.AdminSideBar{
    color: black;
    display: flex;
    flex-direction: column;
    width: 280px;
    // width: 80px !important;
    position: fixed;
    // flex: 1;

    .SidebarItem{
        flex-direction: row;
        display: flex;
        padding-left: 12px;
        cursor: pointer;
        padding-top: 12px;
        padding-bottom: 15px;
        align-items: center;
        // justify-content: center;
        border-left: 3px solid white;

        .Icon{
            color: black;
            font-size: 18px;
        }

        .SidebarTitle{
            margin-left: 6px;
            color: black;
            font-size: 13px;
            line-height: 18px;
            font-weight: 520;
        }
    }

    .SideBarActive{
        border-left: 3px solid #074f51;
        background-color: #c2f0c2;
    }

    .ImageSideBar{
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e2e2e2;
        margin-bottom: 15px;
        // background-color: #f2f2f2;

        .Image{
            height: 100px;
            width: 100px;
            border-radius: 50px;
            border: 1px solid #e2e2e2;
        }

        .AdminSideBar_Title{
            margin-top: 15px;
            color: black;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
        }

    }

    .ButtonChangeState{
        cursor: pointer;
        position: absolute;
        top: 160px;
        right: -10px;
        font-size: 15px;
        background-color: white;
        color: black;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e2e2e2;
    }

    .ButtonChangeState:hover{
        background-color: lightgreen;
    }
}

.AdminSideBarSmall{
    color: black;
    display: flex;
    flex-direction: column;
    width: 70px !important;


    .ImageSideBar{
        display: none;
    }

    .SidebarItem{
        flex-direction: row;
        display: flex;
        padding-left: 15px;
        cursor: pointer;
        padding-top: 15px;
        padding-bottom: 15px;
        align-items: center;
        // justify-content: center;
        border-left: 5px solid white;

        .Icon{
            color: black;
            font-size: 25px;
        }

        .SidebarTitle{
            margin-left: 6px;
            color: black;
            font-size: 16px;
            // font-weight: bold;
            display: none;
        }
    }

    .SideBarActive{
        border-left: 4px solid #074f51;
        background-color: #c2f0c2;
    }

    .ButtonChangeState{
        cursor: pointer;
        position: absolute;
        top: 40vh;
        right: -10px;
        font-size: 15px;
        background-color: white;
        color: black;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #c2c2c2;
    }

    .ButtonChangeState:hover{
        background-color: lightgreen;
    }
}

@media(max-width: 500px){
    .AdminSideBarSmall{
        display: none;
    }

    .AdminSideBar{
        background-color: white;
        position: absolute !important; 
        top: 0px;
        left: 0px;
        height: calc(100vh - 70px) !important;
        z-index: 900;
        width: 80%;
        border-right: 1px solid #f2f2f2;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.011);
    }
}