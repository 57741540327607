.UserHistoryPaymentPage {
    flex: 1;
    background-color: #f2f2f2;
    padding: 10px 0px 0px 0px;
    // padding-left: 50px;
    // padding-right: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .UHP_Title {
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        // margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .UHP_PaginateSection {
        display: flex;
        // flex: 1;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        .Pagination {
            justify-content: flex-end;
        }
    }
}

@media (max-width: 800px) {
    .miniHide {
        display: none;
    }
}

@media (max-width: 400px) {
    .superMiniHide {
        display: none;
    }
}
