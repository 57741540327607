.CDS_Table {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    // width: 100%;

    .CDS_TableRowHeader {
        display: flex;
        flex-direction: row;
        // text-align: center;
        padding: 15px 0px 15px 0px;
        border-bottom: 1px solid #e2e2e2;
        // margin-bottom: 10px;

        .CDS_HeaderText {
            color: black;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            display: flex;
            flex-direction: "row";
            align-items: center;
            justify-content: center;
        }
    }

    .CDS_TableRow {
        display: flex;
        flex-direction: row;
        // text-align: center;
        padding: 25px 0px 25px 0px;
        border-bottom: 1px solid #e2e2e2;
        // margin-bottom: 10px;

        .CDS_TableCell {
            color: black;
            font-weight: 450;
            font-size: 13px;
            line-height: 17px;
            display: flex;
            flex-direction: "row";
            align-items: center;
            justify-content: center;
        }
    }
}