.ListFlashSale {
    .LFS_Actions {
        margin-bottom: 10px;
        .LFS_ButtonAdd {
            padding: 10px;
        }
    }
    .LFS_Pagination {
        .Pagination {
            justify-content: flex-end;
        }
    }

    .LFS_Table_Action_Delete {
        background-color: #ffa500;
    }

    .LFS_Table_Action_Edit{
        margin-right: 6px;
    }
}

.AdminModalAddFlashSale {
    .AMAFS_Label {
        text-transform: uppercase;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        text-align: center;
        margin-bottom: 8px;
    }
    .AMAFSI_Label {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-top: 10px;
    }
    .AMAFSI_Value {
        padding: 6px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        width: 100%;
        font-size: 14px;
    }
    .AMAFSF_Action {
        margin-top: 14px;
    }
    .AMAFSF_Button {
        margin-right: 8px;
    }
    .AMAFSF_Button_Cancel {
        background-color: #ffa500;
    }
    .AMAFSI_DateTime {
        margin-right: 10px;
    }
}