.AdminTopBar {
	// background-color: pink;

	// color: red;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding-right: 20px;
}

.AdminTopBar {
	background-color: #f2f2f2;
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	// position: fixed;
	// left: 0;
	// top: 0;
	width: 100%;
	margin: auto;
	z-index: 999;
	border-bottom: 1px solid #e2e2e2;
	// color: white;

	.NameApplication {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		// background-color: lightblue;
		padding-left: 20px;

		.Title {
			color: white !important;
			font-weight: 700;
			margin-right: 10px;
			font-size: 20px;
			line-height: 24px;
		}

		.AdminTopBar_ShowDrawerBar {
			display: none;

			.ATS_Icon {
				color: white;
				font-size: 30px;
				cursor: pointer;
			}
		}
	}

	.MainHeader {
		display: flex;
		height: 50px;
		align-items: center;

		color: black;
		font-weight: bold;
		margin-right: 10px;
		font-size: 20px;
	}

	.AvatarView {
		display: flex;
		flex-direction: row;
		margin-right: 30px;
		height: 50px;
		justify-content: flex-end;
		align-items: center;
		// background-color: green;
		padding-left: 20px;
		cursor: pointer;

		.Icon {
			margin-top: -25px;
			margin-right: 10px;
			font-size: 30px;
			color: white;
			z-index: -1 !important;
			position: relative;
		}

		.badge {
			height: 20px;
			width: 20px;
			border-radius: 10px;
			background-color: #ff5c33;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 11px;
			margin-left: 13px;
			z-index: 10 !important;
		}

		.Avatar {
			height: 45px;
			width: 45px;
			border-radius: 23px;
			border: 1px solid #e2e2e2;
		}

		.arrow-up {
			width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid white;
			-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			box-shadow: 0 4px 6px -6px #222;
			position: relative;
			margin-left: 150px;
		}

		.info {
			background-color: white;
			display: flex;
			flex-direction: column;
			flex: 1;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			text-align: center;
			padding-top: 10px;
			border-radius: 5px;
			// align-items: center;
			// background-color: pink;

			.ZAvatar {
				height: 60px;
				width: 60px;
				border-radius: 30px;
				border: 1px solid #e2e2e2;
				display: flex !important;
				align-self: center;
			}

			.UserName {
				margin-top: 10px;
				color: black;
				font-size: 17px;
				font-weight: bold;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid #d2d2d2;
			}

			.rowFunction {
				//   width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				padding-left: 10px;
				align-items: center;
				cursor: pointer;
				padding-top: 5px;
				padding-bottom: 5px;
				font-size: 15px;
				color: black;
				//   background-color: pink;

				.icon {
					font-size: 15px;
					margin-right: 5px;
					height: 100%;
					margin-top: 0px;
					margin-bottom: 3px;
					// background-color: white;
				}
			}

			.rowFunction:hover {
				background-color: #70db70;
			}
		}

		.TopbarInfo {
			position: absolute;
			background-color: transparent;
			width: 200px;
			height: 220px;
			top: 75px;
			right: 30px;
			z-index: 1;
			display: flex;
			flex-direction: column;
			border-radius: 10px;

			.info::before {
				left: 150px;
			}
		}

		.info::before {
			content: "";
			position: absolute;
			transform: rotate(45deg);
			width: 12px;
			height: 12px;
			top: -6px;
			left: 150px;
			z-index: 2;
			background-color: white;
			box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
			// border-width: 1px 1px 1px 1px;
		}

		.MessagePreview {
			position: absolute;
			background-color: transparent;
			width: 200px;
			height: 220px;
			top: 75px;
			right: 10px;
			z-index: 1;
			display: flex;
			flex-direction: column;
			border-radius: 10px;

			.info::before {
				left: 60px;
			}
		}

		.NotifycationPreview {
			position: absolute;
			background-color: transparent;
			width: 200px;
			height: 220px;
			top: 75px;
			right: 30px;
			z-index: 1;
			display: flex;
			flex-direction: column;
			border-radius: 10px;
		}

		.info::before {
			left: 98px;
		}
	}
}

.hide {
	display: none !important;
}

.ModalChangePassword {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 300px;
	margin-left: 15px;
	margin-right: 15px;
	z-index: 1000;

	.ControlPanel {
		display: flex;
		flex-direction: row;
		margin-top: 20px;
		justify-content: flex-end;

		.Button {
			margin-left: 10px;
			background-color: #3399ff;
			color: white;
			padding: 5px;
			padding-left: 20px;
			padding-right: 20px;
			border-radius: 5px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			.icon {
				margin-right: 5px;
			}
		}

		.Button:hover {
			background-color: #2288ee;
		}
	}

	.RowInfo {
		margin-top: 10px;
		display: flex;
		flex-direction: column;

		.Title {
			font-weight: bold;
			margin-bottom: 3px;
		}

		input {
			display: flex;
			flex: 1;

			background-color: #f2f2f2;
			border: none;
			padding: 4px;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 3px;
		}

		input:focus {
			outline: none;
		}
	}
}

@media (max-width: 500px) {
	.NameApplication {
		.Title {
			display: none !important;
		}

		.AdminTopBar_ShowDrawerBar {
			display: flex !important;
		}
	}
}
