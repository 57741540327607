// mobile css
@media only screen and (max-width: 600px) {
    .footer {
        background-color: #F2F2F2 !important;
    }
    .footer-middle {
        padding-top: 18px !important;
        .intro-cadosa {
            font-size: 12px;
            color: #808080;
        }
        .widget {
            font-size: 16px;
        }
        .widget-title {
            font-size: 16px;
        }
    }
    .footer-bottom {
        align-items: center !important;
        & p {
            margin-bottom: 0px !important;
        }
    }
    .footer-contact {
        text-align: center !important;
        font-size: 12px;
        .contact-widget {
            .widget-title {
                font-size: 12px;
                margin-bottom: 8px;
            }
        }
    }
}