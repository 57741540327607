.history-voucher {
    padding: 20px;
    width: 100%;
    color: #000000;
    &-header {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.table-history {
    overflow: auto;
}

.table-history-table {
    width: 100%;
    thead {
        background-color: #2eb33c;
        color: #ffffff;
    }
}

.table-history-table th{
    border: 1px solid #e2e2e2;
    padding: 10px;
}

.table-history-table td {
    border: 1px solid #e2e2e2;
    padding: 10px;
}

.table-row--gray {
    background-color: #e2e2e2;
}