.UserHistoryBillPage {
  flex: 1;
  background-color: #f2f2f2;
  padding: 10px 0px 0px 0px;
  // padding-left: 50px;
  // padding-right: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .UHB_Title {
    color: black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    // margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .UHB_Action {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

    .UHB_Btn_Add {
      display: flex;
      flex-direction: row;
      background-color: #074f51;
      color: white;
      padding: 8px 10px;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .UHB_Btn_Icon {
        margin-right: 5px;
      }
    }

    .UHB_Btn_Add:hover {
      background-color: #074f51;
    }
  }

  .UHB_PaginateSection {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    .Pagination {
      justify-content: flex-end;
    }
  }

  .FilterSearchAndSort {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .APL_SearchBoxPanel {
      cursor: pointer;
      margin-right: 10px;
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      align-items: center;
      margin-top: -10px;

      .APL_SearchBox {
        background-color: white;
        padding: 8px;
        padding-left: 15px;
        padding-right: 30px;
        border-radius: 3px;
        font-weight: 600;
        border: none;
        min-width: 200px;
        border-radius: 4px;
      }

      .APL_SearchBoxIcon {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
      }
    }

    .dropdown {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .dropdown2 {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      // background-color: #f1f1f1;
      min-width: 250px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 10001 !important;
      right: 0px;
      top: 35px;
      background-color: white;
    }

    .dropdown-content .APL_DropDownItem {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      // justify-content: center;
      align-items: center;
      cursor: pointer;

      .APL_Icon {
        margin-right: 10px;
      }
    }

    .dropdown-content .APL_DropDownItem:hover {
      background-color: #c2f0c2;
    }

    .dropdown:hover .dropdown-content {
      display: flex;
      flex-direction: column;
    }

    .APL_ButtonAction {
      background-color: #074f51;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 8px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 5px;

      font-size: 13px;
      line-height: 17px;
      font-weight: 600;
    }
  }
}

@media (max-width: 400px) {
  .UserHistoryBillPage {
    padding: 5px 0px 0px 0px;
    overflow: scroll;

    .UHB_Table {
      padding: 5px;
      overflow: scroll;
    }
  }

  .pagination {
    margin: 0px;
  }
}
