.warehouse-create {
  background-color: white;
  padding: 20px;
  padding-top: 10px;
  border-radius: 3px;
  .viewHeader {
    margin-bottom: -5px;
    flex-direction: row;
    align-items: center;
    display: flex;
    .nameHeader {
      flex: 1;
      font-size: 18px;
      color: black;
      font-weight: bold;
    }
  }

  .headerUser {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nameInput {
    font-size: 14px;
    color: black;
    font-weight: 500;
    margin-top: 20px;
  }

  .MSU_User_Row {
    display: flex;
    flex-direction: row;
    width: 70%;
    background-color: #f2f2f2;
    margin-bottom: 5px;
    padding: 5px;
    align-items: center;
    position: relative;
    margin-right: 10px;
    border-radius: 3px;
    margin-bottom: 5px;

    .MSU_ImageView {
      margin-right: 5px;
      margin-left: 15px;

      .MSU_UserAvatar {
        height: 46px;
        width: 46px;
        border-radius: 23px;
        object-fit: cover;
        border: 1px solid #c2c2c2;
      }
    }

    .MSU_UserName {
      color: black;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}
