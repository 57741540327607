// @font-face {
//   font-family: sans-serif;
//   src: url("./Tahoma Regular font.ttf");
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }

.d-container {
  p {
    padding: 0 !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    font-family: sans-serif;
  }
  align-self: center;

  width: 105mm;
  height: 100%;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;

  padding-left: 5px;
  padding-right: 5px;

  .d-table {
    width: 100%;
    padding-top: 4px;
  }

  .d-title {
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-align: center;
    font-family: sans-serif;
  }

  .d-des {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-top: 2px !important;
  }

  .d-space {
    border-bottom: 1px dashed #000;
  }

  .d-space-top {
    border-top: 1px dashed #000;
  }

  .d-mt-5 {
    margin-top: 5px !important;
  }

  .d-mt-10 {
    margin-top: 10px !important;
  }

  table,
  td,
  th {
    text-align: left;
    font-size: 12px;
    empty-cells: show;
    flex: 1;
    font-family: sans-serif;
  }
  .td {
    font-weight: 600;
    letter-spacing: 1px;
    // line-height: 20px;
  }
  .no-break {
    page-break-inside: avoid;
  }

  .d-table-title {
    font-weight: 500;
    font-size: 12px;
    color: #000;
    text-align: center;
  }

  .td-2 {
    font-size: 13px;
  }

  .d-text-left {
    text-align: left !important;
  }

  .d-text-right {
    text-align: right;
  }

  .d-pb-5 {
    padding-bottom: 10px !important;
  }

  .d-pt-5 {
    padding-top: 10px !important;
  }

  .longCol {
    text-align: right;
  }

  .numberCellRight,
  td {
    text-align: right;
  }

  .d-bottom {
    font-size: 11px;
    margin-top: 5px !important;
    color: #000;
  }
}
