.u_profile_container {
  display: flex;
  flex-direction: row;

  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  // #f3f3f3;
  // background-color: plum;
  min-height: 600px;

  .u_profile_content {
    display: flex;
    flex: 1;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    // overflow: scroll;
  }
}

// mobile css
@media only screen and (max-width: 600px) {
  .u_profile_container {
    .u_profile_content {
      width: 100%;
    }
  }
}
